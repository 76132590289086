import React from 'react';
import PropTypes from 'prop-types';
import { useField, Field, useFormikContext } from 'formik';
import CurrencyInput from 'react-currency-input';

const CurrencyMaskedTextInput = ({ ...props }) => {
	const [field, meta] = useField(props);
	const { setFieldValue } = useFormikContext();
	const { className } = props

	const handleChange = (event, maskedvalue, floatvalue) => {
        setFieldValue(field.name, floatvalue)
    }
	return (
		<>
			<Field {...field} {...props}>
				{({ field, meta }) => (
					<CurrencyInput
						{...field}
						{...props}
						decimalSeparator=','
						thousandSeparator='.'
						prefix='R$ '
						className={`${
							meta.error && `border border-secondary`} form-control ${className}`}
						onChangeEvent={handleChange}
						
					/>
				)}
			</Field>

			{meta.error ? (
				<div className='ml-1 form-be-custom-error'>{meta.error}</div>
			) : null}
		</>
	);
};
CurrencyMaskedTextInput.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	className: PropTypes.string.isRequired,
};

export default CurrencyMaskedTextInput;
