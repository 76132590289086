export const GET_MINHAS_INSTITUICOES = 'GET_MINHAS_INSTITUICOES';
export const GET_MINHAS_OPERACOES_BY_SK = 'GET_MINHAS_OPERACOES_BY_SK';
export const SET_SHOW_MODAL_NOVO_ATIVO = 'SET_SHOW_MODAL_NOVO_ATIVO';
export const SET_ATIVO_OPERACAO = 'SET_ATIVO_OPERACAO';
export const ADD_OPERACOES = 'ADD_OPERACOES';
export const UPDATE_OPERACOES = 'UPDATE_OPERACOES';
export const REMOVE_OPERACOES = 'REMOVE_OPERACOES';
export const ERROR_GENERIC = 'ERROR_GENERIC';
export const SET_INSTITUICAO = 'SET_INSTITUICAO';
export const SET_LOADING_GENERIC = 'SET_LOADING_GENERIC';
