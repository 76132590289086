import React from 'react';
import { connect } from 'react-redux';

import { setShowModalNovoAtivo } from './action/MinhasOperacoesAction';
import Title from '../../../../components/common/Title';
import Row from '../../../../components/common/Row';
import Erro500 from '../../../../components/common/Erro500';
import Instituicoes from './manual/Instituicoes';
import ModalAddUpdateOperacaoAtivo from './manual/ModalAddUpdateOperacaoAtivo';
import ModalConectemeCei from '../../usuario/ModalConectemeCei';
import ValidarConexaoCEI from '../ValidarConexaoCEI';
// import Card from '../../../components/common/Card';
// import CardBody from '../../../components/common/CardBody';
import Button from '../../../../components/form/Button';

const MinhasOperacoes = (props) => {
	const {
		setShowModalNovoAtivo,
		erroGetInvestidorGeneric,
		erroInvestidorGeneric,
		isErroGetAutorizacaoCei,
		erroConnectmeCei
	} = props;

	if (erroGetInvestidorGeneric || isErroGetAutorizacaoCei) {
		let msg = 'erro padrão';
		if (erroConnectmeCei !== null) {
			msg = erroConnectmeCei;
		} else if (erroGetInvestidorGeneric !== null) {
			msg = erroInvestidorGeneric;
		}
		return <Erro500 erroMsg={msg} />;
	}

	return (
		<>
			<Title
				text='Minhas Operações'
				textTooltip={`Aqui você atualiza sua carteira de investimentos lançando as operações de compra e venda dos seus ativos financeiros. Escolha a instituição financeira que você usa para operar 
					seus investimentos e insira os últimos aportes realizados que nós atualizamos sua carteira de investimentos.`}
			/>
			{/* IF para saber se tá conectado ao CEI */}
			<Row>
				<div className='col-12 text-right mb-3'>
					<ValidarConexaoCEI />
				</div>
			</Row>
			<Row>
				<div className='col-12 mb-3'>
					<Instituicoes />
				</div>
			</Row>
			<Row>
				<div className='col-12 text-right'>
					<Button
						icon='fas fa-plus'
						texto='Nova Operação'
						classes='btn btn-primary'
						onClick={() => setShowModalNovoAtivo(true)}
						disabled={false}
					/>
				</div>
			</Row>
			<ModalConectemeCei />
			<ModalAddUpdateOperacaoAtivo />
		</>
	);
};

const mapStateToPros = (state) => ({
	erroGetInvestidorGeneric: state.operacoesStore.erroGetInvestidorGeneric,
	erroInvestidorGeneric: state.operacoesStore.erroInvestidorGeneric,
	isErroGetAutorizacaoCei: state.usuarioStore.isErroGetAutorizacaoCei,
	erroConnectmeCei: state.usuarioStore.erroConnectmeCei
});

export default connect(mapStateToPros, { setShowModalNovoAtivo })(
	MinhasOperacoes
);
