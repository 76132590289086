export const GET_MEUS_OBJETIVOS = 'GET_MEUS_OBJETIVOS';
export const GET_MEUS_OBJETIVOS_DETALHES = 'GET_MEUS_OBJETIVOS_DETALHES';
export const SET_DATA_CONTEXT_OBJETIVOS = 'SET_DATA_CONTEXT_OBJETIVOS';
export const SET_LOADING_OBJETIVOS = 'SET_LOADING_OBJETIVOS';
export const SET_LOADING_OBJETIVOS_DETALHES = 'SET_LOADING_OBJETIVOS_DETALHES';
export const SET_OBJETIVO = 'SET_OBJETIVO';
export const SET_SHOW_MODAL_OBJETIVOS = 'SET_SHOW_MODAL_OBJETIVOS';
export const SET_SHOW_MODAL_ANALISE_OBJETIVO = 'SET_SHOW_MODAL_ANALISE_OBJETIVO';
export const ADD_OBJETIVO = 'ADD_OBJETIVO';
export const UPDATE_OBJETIVO = 'UPDATE_OBJETIVO';
export const ERROR_OBJETIVOS = 'ERROR_OBJETIVOS';
export const CLEAR_STATE_OBJETIVO = 'CLEAR_STATE_OBJETIVO';
export const REMOVE_OBJETIVO = 'REMOVE_OBJETIVO';
export const HANDLE_OPTION_OBJETIVOS = 'HANDLE_OPTION_OBJETIVOS';
export const SET_INDEX_ROW = 'SET_INDEX_ROW';
export const SET_OBJETIVOS_DETALHES_TO_NULL = 'SET_OBJETIVOS_DETALHES_TO_NULL';
export const SET_TOTAL_ACUMULADO_OBJETIVOS = 'SET_TOTAL_ACUMULADO_OBJETIVOS';
export const GET_ARRAY_SERIE_MEUS_OBJETIVOS = 'GET_ARRAY_SERIE_MEUS_OBJETIVOS';
export const GET_ARRAY_SERIE_EVOLUCAO_PATRIMONIAL = 'GET_ARRAY_SERIE_EVOLUCAO_PATRIMONIAL';
