import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setTabActiveById } from './action/TabsAction';

const TabItem = (props) => {
	const { id, text, setTabActiveById, tabSelected } = props;

	let active = id === tabSelected ? 'active show' : '';

	const setTab = () => {
		setTabActiveById(id);
	};
	return (
		<li className="nav-item">
			<a
				className={`nav-link ${active}`}
				data-toggle='tab'
				href={`#${id}`}
				aria-expanded='true'
				onClick={setTab}
			>
				<span>{text}</span>
			</a>
		</li>
	);
};

TabItem.propTypes = {
	id: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	tabSelected: state.tabStore.tabSelected
});

export default connect(mapStateToProps, { setTabActiveById })(TabItem);
