import React from 'react';
import { connect } from 'react-redux';
// import SimpleBar from 'simplebar-react';

import Row from '../../../components/common/Row';
import Tooltip from '../../../components/common/Tooltip';
import { calculaPrazo , produtosObjetivos } from './helper/utils';

const OpcoesInvestimentos = ({ objetivo }) => {
	const renderList = (prazo) => {
        const prazoCalculado = calculaPrazo(prazo);
        const produtos =  produtosObjetivos.filter((item) => item.prazo === prazoCalculado);
		return (
			<ul>
				{produtos.map((item, index) => (
					<li key={index}>
                        {item.produto}
						<Tooltip text={item.descricao} icon='fas fa-1x fa-info-circle' />
					</li>
				))}
			</ul>
		);
	};
	return (
		objetivo && (
			<Row classes='mb-3'>
				<div className='col-12 text-center'>
					<p className='h5'>Opções de investimento para cada objetivo</p>
				</div>
				<div className='col-12'>
					{/* <SimpleBar style={{ maxHeight: '100%', width: '100%' }}> */}
					<table className='table table-centered mb-0'>
						<thead className='thead-light'>
							<tr>
								{/* <th></th> */}
								<th>Prazo</th>
								<th style={{ width: '70%' }}>Opções de investimento</th>
							</tr>
						</thead>
						<tbody>
							<tr key='1'>
								{/* <td>
                                <span className='text-gray-800'>Icon</span>
                            </td> */}
								<td>{calculaPrazo(objetivo.prazo, true)}</td>
								<td>{renderList(objetivo.prazo)}</td>
							</tr>
						</tbody>
					</table>
					{/* </SimpleBar> */}
				</div>
			</Row>
		)
	);
};

const mapStateToProps = (state) => ({
	objetivo: state.fazendoCaixaStore.objetivo
});
export default connect(mapStateToProps)(OpcoesInvestimentos);
