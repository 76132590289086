import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import avatar from '../../../../../assets/img/default-avatar.png';
import { logout } from '../../../../../layouts/minimal/autenticacao/action/AuthAction';
import { setSideBarToggle } from '../../sidebar/action/SideBarAction';

const Usuario = (props) => {
	const { logout, usuario, setSideBarToggle } = props;

	return (
		<li className='nav-item dropdown no-arrow'>
			<a
				className='nav-link dropdown-toggle tour-passo-usuario'
				href='usuario'
				id='userDropdown'
				role='button'
				data-toggle='dropdown'
				aria-haspopup='true'
				aria-expanded='false'
			>
				{usuario !== null && (
					<span className='mr-2 d-none d-lg-inline text-gray-900'>
						{usuario.attributes.name}
					</span>
				)}
				<img className='img-profile rounded-circle' src={avatar} alt='' />
			</a>
			{/* <!-- Dropdown - User Information --> */}
			<div
				className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
				aria-labelledby='userDropdown'
			>
				<Link
					className='dropdown-item text-gray-600'
					to='/dashboard/usuario/minha-conta'
					onClick={() => {
						setSideBarToggle('toggled');
					}}
				>
					<i className='fas fa-user fa-sm fa-fw mr-2'></i>
					Minha conta
				</Link>
				<Link
					className='dropdown-item text-gray-600'
					to='/dashboard/usuario/conecte-me'
					onClick={() => {
						setSideBarToggle('toggled');
					}}
				>
					<i className='fas fa-cogs fa-sm fa-fw mr-2'></i>
					Conecte-me
				</Link>
				<Link
					className='dropdown-item text-gray-600'
					to='/dashboard/usuario/minhas-atividades'
					onClick={() => {
						setSideBarToggle('toggled');
					}}
				>
					<i className='fas fa-list fa-sm fa-fw mr-2'></i>
					Minhas atividades
				</Link>
				<div className='dropdown-divider'></div>
				<Link
					className='dropdown-item text-gray-600'
					to='/login'
					onClick={() => logout()}
				>
					<i className='fas fa-sign-out-alt fa-sm fa-fw mr-2'></i>
					Sair
				</Link>
			</div>
		</li>
	);
};

const mapStateToProps = (state) => ({
	usuario: state.authStore.usuario
});

export default connect(mapStateToProps, {
	logout,
	setSideBarToggle
})(Usuario);
