import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";

import HeaderBotoesMeses from "../../../components/common/HeaderBotoesMeses";
import {
  getMinhasContasTreemap,
  setLoadingLancamentosTreemap,
  setDataContextLancamentosTreemap
} from "./action/ArrumandoCasaAction";
import { setErroAutenticacao } from "../../../minimal/autenticacao/action/AuthAction";
import Card from "../../../components/common/Card";
import CardBody from "../../../components/common/CardBody";
import CardHeader from "../../../components/common/CardHeader";
import Row from "../../../components/common/Row";
import Spinner from "../../../components/preLoader/Spinner";
import Tooltip from "../../../components/common/Tooltip";
import { formataMoeda } from "../../../../config/helper/utils";
import NaoHaLancamento from "./NaoHaLancamento";

const GraficoTreemapLancamentos = (props) => {
  const {
    dataContextLancamentosTreemap,
    loadingLancamentosTreemap,
    lancamentosTreemap,
    getMinhasContasTreemap,
    setLoadingLancamentosTreemap,
    setErroAutenticacao,
	setDataContextLancamentosTreemap
  } = props;

  useEffect(() => {
    setLoadingLancamentosTreemap(true);

    getMinhasContasTreemap(dataContextLancamentosTreemap)
      .then(() => {
        setLoadingLancamentosTreemap(false);
      })
      .catch((err) => {
        if (err.code === "NotAuthorizedException") {
          setErroAutenticacao(err);
        }
        setLoadingLancamentosTreemap(false);
      });

    // console.log(lancamentosTreemap);
    // console.log(dataContextLancamentosTreemap);
    // console.log(loadingLancamentosTreemap);

    // eslint-disable-next-line
  }, [dataContextLancamentosTreemap, getMinhasContasTreemap]);

  const graphicsConfig = {
    series: [
      {
        data: lancamentosTreemap !== null ? lancamentosTreemap : [],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
        toolbar: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return formataMoeda(val);
          },
        },
      },
      // colors: ['#0acf97', '#727cf5', '#74d7d7', '#17a2b8', '#087e8b', '#0acf97'] //Curto, medio, Longo prazo
    },
  };

  return (
    <>
      <Card classes="mb-4 tour-passo-grafico-recebimento-gastos">
        <CardHeader classes="d-flex flex-row align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">
            Análise da distribuição financeira por lançamento
          </h6>
          <Tooltip
            text={`O gráfico mostra a distribuição dos lançamentos de acordo com o valor, ou seja, quanto maior o valor, maior o espaço ocupado no gráfico.`}
            icon="fas fa-1x fa-info-circle"
            trigger="hover"
          />
        </CardHeader>
        {/* <!-- Card Body --> */}
        <CardBody>
          <Row>
            <div className="mb-3 col-12">
              <HeaderBotoesMeses
                functionName={setDataContextLancamentosTreemap}
                dataRedux={dataContextLancamentosTreemap}
              />
            </div>
          </Row>
          {loadingLancamentosTreemap ? (
            <Spinner id="spinner-centro-sm" />
          ) : lancamentosTreemap !== null && lancamentosTreemap.length > 0 ? (
            <Row>
              <div
                id="lancamentos-treemap-chart"
                className="apex-charts col-12"
              >
                <ReactApexChart
                  options={graphicsConfig.options}
                  series={graphicsConfig.series}
                  type="treemap"
                  height={350}
                />
              </div>
            </Row>
          ) : (
            <NaoHaLancamento height="60vh" />
          )}
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  dataContextLancamentosTreemap:
    state.arrumaCasaStore.dataContextLancamentosTreemap,
  loadingLancamentosTreemap: state.arrumaCasaStore.loadingLancamentosTreemap,
  lancamentosTreemap: state.arrumaCasaStore.lancamentosTreemap,
});

export default connect(mapStateToProps, {
  getMinhasContasTreemap,
  setLoadingLancamentosTreemap,
  setErroAutenticacao,
  setDataContextLancamentosTreemap
})(GraficoTreemapLancamentos);
