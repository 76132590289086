import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
	setShowModalConectemeCei,
	getAutorizacaoCei
} from './action/UsuarioAction';
import Title from '../../../components/common/Title';
import Row from '../../../components/common/Row';
import Card from '../../../components/common/Card';
import CardBody from '../../../components/common/CardBody';
import Tooltip from '../../../components/common/Tooltip';
import Button from '../../../components/form/Button';
import ModalConectemeCei from './ModalConectemeCei';
import { callAlert } from '../../../../config/helper/utils';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';

const ConecteMe = (props) => {
	const {
		setShowModalConectemeCei,
		getAutorizacaoCei,
		erroAutenticacao,
		isErroGetAutorizacaoCei,
		erroConnectmeCei,
		setErroAutenticacao
	} = props;

	useEffect(() => {
		getAutorizacaoCei()
			.then(() => {})
			.catch((err) => {
				if (err.code === 'NotAuthorizedException') {
					setErroAutenticacao(err);
				}
			});
		// eslint-disable-next-line
	}, []);

	if (erroAutenticacao !== null) {
		return <Redirect to='/login' />;
	}
	// else if (isErroGetAutorizacaoCei && erroConnectmeCei !== null) {
	// 	return <Erro500 erro={erroConnectmeCei} />;
	// }

	return (
		<>
			<Title text='Conecte-me às instituições financeiras' hasTooltip={false} />
			<Row classes='mt-4'>
				<div className='col-12 text-justify'>
					<p className='text-gray-600'>
						Quer que o Beganinha faça todo o trabalho de atualização da carteira
						por você? Então aqui é o lugar que você nos diz se quer ficar
						conectado com as instituições financeiras com que você trabalha.
						<br></br>
						Atualmente te conectamos ao CEI, da B3, mas já estamos trabalhando
						para aumentar nossa lista de parceiros.
					</p>
				</div>
			</Row>
			<Row>
				<div className='col-3'>
					<Card classes='shadow'>
						<CardBody>
							<Row>
								<div className='col-12 text-center'>
									<p className='text-primary h5 font-weight-bold mb-3'>CEI</p>
								</div>
							</Row>
							<Row classes='mb-4'>
								<div className='col-12 text-center'>
									<span className='text-wrap'>
										Canal Eletrônico do Investidor
										<Tooltip
											text={`CEI -Canal Eletrônico do Investidor- é um portal disponibilizado pela B3 que possibilita ao investidor ter uma visão consolidada de seus investimentos.`}
											icon='fas fa-1x fa-info-circle'
											trigger='hover'
										/>
									</span>
								</div>
							</Row>
							<Row classes='mb-2'>
								<div className='col-12 text-center'>
									<p className='text-center my-3'>
										<Button
											classes='btn btn-primary'
											texto='Conecte-me'
											id='btn-salvar'
											disabled={false}
											onClick={() => {
												setShowModalConectemeCei(true);
											}}
										/>
									</p>
								</div>
							</Row>
							<Row>
								<div className='col-12 text-left'>
									<p className='text-xs text-gray-700'>
										Conecte-me ao CEI e traga minhas informações de
										investimentos para o Be.
									</p>
								</div>
							</Row>
						</CardBody>
					</Card>
				</div>
			</Row>
			<ModalConectemeCei />
			{process.env.NODE_ENV === 'production' && callAlert(props)}
		</>
	);
};

const mapStateToProps = (state) => ({
	isErroGetAutorizacaoCei: state.usuarioStore.isErroGetAutorizacaoCei,
	erroConnectmeCei: state.usuarioStore.erroConnectmeCei,
	erroAutenticacao: state.authStore.erroAutenticacao
});

export default connect(mapStateToProps, {
	setShowModalConectemeCei,
	getAutorizacaoCei,
	setErroAutenticacao
})(ConecteMe);
