import React from 'react';
import PropTypes from 'prop-types';
import { useField, Field } from 'formik';
import MaskedInput from 'react-text-mask';

const CustomMaskedTextInput = ({ ...props }) => {
	const [field, meta] = useField(props);
	const { className } = props;
	return (
		<>
			<Field
				{...field}
				{...props}
	>
				{({ field, mask, meta }) => (
					<MaskedInput
						mask={mask}
						{...field}
						{...props}
						className={`${
							meta.error && `border border-secondary`
						} form-control ${className}`}
					/>
				)}
			</Field>
			{meta.error ? (
				<div className='ml-1 form-be-custom-error'>{meta.error}</div>
			) : null}
		</>
	);
};
CustomMaskedTextInput.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	mask: PropTypes.array.isRequired,
	className: PropTypes.string.isRequired,
};

export default CustomMaskedTextInput;
