import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import moment from "moment";
import Button from "../../../components/form/Button";
import LogoBeganinha from "../../../components/common/SVGIcon";
import CustomTextInput from "../../../components/form/CustomTextInput";
import CustomMaskedTextInput from "../../../components/form/CustomMaskedTextInput";
import { isCpfValido } from "../../../../config/helper/utils";
import Row from "../../../components/common/Row";
import { criarConta } from "../action/AuthAction";

const AbrirContaForm = (props) => {
  const { criarConta, history, passwordVisibility } = props;

  const [INITIAL_VALUES] = useState({
    nome: "",
    sobrenome: "",
    cpf: "",
    email: "",
    senha: "",
    senhaRepita: "",
    dataNascimento: "",
  });

  const validationSchema = Yup.object({
    nome: Yup.string().required("O nome é obrigatório"),
    sobrenome: Yup.string().required("O sobrenome é obrigatório"),
    cpf: Yup.string()
      .test("CPF-valido", "CPF Inválido", (value) => isCpfValido(value))
      .required("O CPF é obrigatório"),
    email: Yup.string()
      .email("Endereço de e-mail inválido")
      .required("O e-mail é obrigatório"),
    senha: Yup.string()
      .min(8, "A senha deve ter no mínimo oito caracteres com letras e números")
      .required("A senha é obrigatória"),
    senhaRepita: Yup.string()
      .min(8, "A senha deve ter no mínimo oito caracteres com letras e números")
      .oneOf([Yup.ref("senha"), null], "As senhas devem ser iguais")
      .required("A senha é obrigatória"),
    dataNascimento: Yup.string()
      .required("A data de nascimento é obrigatória")
      .test("data-valida", "Data de nascimento inválida", (value) => {
        return moment(value, "DD/MM/YYYY").isValid();
      })
      .test(
        "data-maior",
        "Data de nascimento é maior do que a data de hoje",
        (value) => {
          return moment(value, "DD/MM/YYYY").isBefore(
            moment(new Date(), "DD/MM/YYYY")
          );
        }
      ),
  });

  const onSubmitCriar = (
    values,
    { setFieldError, resetForm, setSubmitting }
  ) => {
    criarConta(values)
      .then((user) => {
        swal({
          title: "Tudo certo!",
          text: `O cadastro foi realizado com sucesso e te enviamos um e-mail com o código para validar tua conta. Vai lá buscá-lo e eu te espero aqui.`,
          icon: "success",
          buttons: {
            depois: {
              text: "Farei isso depois",
              className: "btn-secondary",
              value: false,
            },
            validar: {
              text: "Validar meu cadastro",
              className: "btn-primary",
              value: true,
            },
          },
        }).then((validar) => {
          if (validar) {
            // return props.history.push('/confirmar-conta');
            history.push("/confirmar-conta");
          } else {
            // return props.history.push('/login');
            history.push("/login");
          }
        });
      })
      .catch((error) => {
        if (error.code === "UsernameExistsException") {
          setFieldError("cpf", "CPF já cadastrado.");
        } else {
          swal({
            title: "Ops",
            text: `Tivemos um erro e não conseguimos criar tua conta, mas não se preocupe que já avisamos nosso time de técnicos. Por favor, tente novamente em alguns minutos.`,
            icon: "warning",
            buttons: {
              ok: {
                text: "Ok",
                className: "btn-primary",
              },
            },
          });
          resetForm({});
        }
        setSubmitting(false);
      });
  };

  return (
    <div className="pb-4">
      <div className="d-flex justify-content-center py-3">
        <Link to="/home">
          <LogoBeganinha name="be_preto" classNameCustom="logo-be" />
        </Link>
      </div>

      <div className="text-center pb-3">
        <h1 className="h4">Crie tua conta!</h1>
      </div>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={onSubmitCriar}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form className="form-be-custom" onSubmit={handleSubmit}>
            <Row classes="form-group">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <CustomTextInput
                  name="nome"
                  type="text"
                  placeholder="Nome"
                  className="custom-form-control"
                />
              </div>
              <div className="col-sm-6">
                <CustomTextInput
                  name="sobrenome"
                  type="text"
                  placeholder="Sobrenome"
                  className="custom-form-control"
                />
              </div>
            </Row>
            <div className="form-group">
              <CustomMaskedTextInput
                name="cpf"
                type="tel"
                placeholder="CPF"
                mask={[
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]}
                className="custom-form-control"
              />
            </div>
            <div className="form-group">
              <CustomTextInput
                name="email"
                type="email"
                placeholder="E-mail (nome@provedor.com.br)"
                className="custom-form-control"
              />
            </div>
            <div className="form-group">
              <CustomMaskedTextInput
                name="dataNascimento"
                type="tel"
                placeholder="Data de nascimento"
                mask={[
                  /[0-9]/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                className="custom-form-control"
              />
            </div>
            <Row classes="form-group">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <CustomTextInput
                  name="senha"
                  type={passwordVisibility ? "text" : "password"}
                  placeholder="Senha com letras e números"
                  className="custom-form-control"
                  isPassword={true}
                />
              </div>
              <div className="col-sm-6">
                <CustomTextInput
                  name="senhaRepita"
                  type={passwordVisibility ? "text" : "password"}
                  placeholder="Repita a senha"
                  className="custom-form-control"
				  isPassword={true}
                />
              </div>
            </Row>
            {/* Button */}
            <Button
              classes="btn btn-primary btn-block rounded-pill"
              texto="Criar a conta Be."
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
      <hr />
      <div className="pt-2">
        <Link to="/login" className="text-gray-700">
          Já tem conta? Então clique aqui para entrar na tua conta
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
	passwordVisibility: state.formStore.passwordVisibility,
  });

export default compose(
  withRouter,
  connect(mapStateToProps, { criarConta })
)(AbrirContaForm);
// export default withRouter(AbrirContaForm);
