import { Auth } from 'aws-amplify';

const apiEndpoint = 'https://api.beganinha.com.br/';

export const handleConfigure = () => {
    return {
        Auth: {
            mandatorySignId: true,
            region: "us-east-1",
            userPoolId: "us-east-1_W6JNzUNST",
            userPoolWebClientId: "4ll5tlm5aleifkj7ajloh9leb9"
        },
        API: {
            endpoints: [
                {
                    "name": "be-minhas-contas",
                    "endpoint": apiEndpoint+"dev/contas",
                    custom_header: async () => { 
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                    }
                },
                {
                    "name": "be-meus-objetivos",
                    "endpoint": apiEndpoint+"dev/objetivos",
                    custom_header: async () => { 
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                    }
                },
                {
                    "name": "be-send-emails",
                    "endpoint": apiEndpoint+"dev/email"
                },
                {
                    "name": "be-usuario",
                    "endpoint": apiEndpoint+"dev/usuario",
                    custom_header: async () => { 
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                    }
                },
                {
                    "name": "be-investidor-sucesso",
                    "endpoint": apiEndpoint+"dev/investidor",
                    custom_header: async () => { 
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                    }
                }
            ]
        }
    }
}