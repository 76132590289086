import React from 'react';
import { connect } from 'react-redux';

import { setSideBarToggle } from '../sidebar/action/SideBarAction';

import Usuario from './navbarItens/Usuario';
import Alertas from './navbarItens/Alertas';
const Navbar = (props) => {
	const { setSideBarToggle, sideBarToggle } = props;

	const handleClick = (event) => {
		sideBarToggle === 'toggled'
			? setSideBarToggle('')
			: setSideBarToggle('toggled');
	};

	return (
		<nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
			{/* <!-- Sidebar Toggle (Topbar) --> */}
			<button
				id='sidebarToggleTop'
				className='btn btn-link d-md-none rounded-circle mr-3'
				onClick={() => handleClick()}
			>
				<i className='fa fa-bars'></i>
			</button>
			<ul className='navbar-nav ml-auto'>
				{/* <!-- Nav Item - Alerts --> */}
				{process.env.NODE_ENV === 'development' && <Alertas />}
				<div className='topbar-divider d-none d-sm-block'></div>

				{/* <!-- Nav Item - User Information --> */}
				<Usuario />
			</ul>
		</nav>
	);
};

const mapStateToProps = (state) => ({
	sideBarToggle: state.sideBarStore.sideBarToggle
});

export default connect(mapStateToProps, {
	setSideBarToggle
})(Navbar);
