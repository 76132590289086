import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';

import { getCotacaoAtivo } from './action/MinhaCarteiraAction';
import {
	formataMoeda,
	formataPorcentagem
} from '../../../../../config/helper/utils';
import Button from '../../../../components/form/Button';

const TableCarteiraRendaVariavel = (props) => {
	const [column, setColumn] = useState('tipo');
	const [order, setOrder] = useState(false); //Crescente
	const [reaload, setReload] = useState(false); //Crescente

	const { carteira, getCotacaoAtivo } = props;

	useEffect(() => {
		carteira !== null &&
			carteira.length > 0 &&
			Promise.all(
				carteira.map((item) => {
					getCotacaoAtivo(item.ticker).then((data) => {
                        console.log('--- data --')
						console.log(JSON.stringify(data));
                        console.log('--- data --')
					});
				})
			).then((resAll) => {
                console.log('--- resAll --')
                console.log(JSON.stringify(resAll));
                console.log('--- resAll --')
            });
	}, [reaload]);

	return (
		<div className='table-responsive mt-sm-4'>
			<SimpleBar style={{ maxHeight: 600 }}>
				<table className='table table-striped mb-0'>
					<thead>
						<tr>
							<th
								scope='col'
								className='sticky-col first-col mx-0'
								onClick={() => {
									setColumn('ticker');
									setOrder(!order);
								}}
							>
								Ticker
								<span className='ml-2'>
									{order && column === 'ticker' ? (
										<i className='fas fa-sort-up'></i>
									) : (
										<i className='fas fa-sort-down'></i>
									)}
								</span>
							</th>
							<th
								scope='col'
								onClick={() => {
									setColumn('tipo');
									setOrder(!order);
								}}
							>
								Tipo
								<span className='ml-2'>
									{order && column === 'tipo' ? (
										<i className='fas fa-sort-up'></i>
									) : (
										<i className='fas fa-sort-down'></i>
									)}
								</span>
							</th>
							<th scope='col'>Nome</th>
							<th scope='col'>Quantidade</th>
							<th scope='col'>Preço Médio</th>
							<th scope='col'>Cotação</th>
							<th scope='col'>Valor Total/Cotação</th>
							<th scope='col'>Valor Total/Preço Médio</th>
							<th scope='col'>Peso/Preço médio (%)</th>
							<th scope='col'>Peso/Preço médio (%)</th>
							<th scope='col'>Valor de Venda</th>
							<th scope='col'>Lucro Líquido</th>
							<th scope='col'>%</th>
							<th scope='col' style={{ width: '125px' }}>
								Ações
							</th>
						</tr>
					</thead>
					<tbody>
						{carteira
							.sort((a, b) => {
								if (order) {
									return a[column] < b[column]
										? -1
										: a[column] > b[column]
										? 1
										: 0;
								} else {
									return b[column] < a[column]
										? -1
										: b[column] > a[column]
										? 1
										: 0;
								}
							})
							.map((item, index) => (
								<tr key={index}>
									<td>{item.ticker}</td>
									<td>{item.tipo}</td>
									<td>{item.companhia}</td>
									<td>{item.quantidade}</td>
									<td>
										{formataMoeda(item.precoMedio > 0 ? item.precoMedio : 0)}
									</td>
									<td>{}</td>
									<td>R$000</td>
									<td>
										{formataMoeda(
											item.valorTotalPM > 0 ? item.valorTotalPM : 0
										)}
									</td>
									<td>
										{formataPorcentagem(
											item.participacaoEmReal > 0 ? item.participacaoEmReal : 0
										)}
									</td>
									<td>
										{formataPorcentagem(
											item.participacaoEmQtde > 0 ? item.participacaoEmQtde : 0
										)}
									</td>
									<td>R$000</td>
									<td>R$000</td>
									<td>{formataPorcentagem(0)}</td>
									<td>
										<Button
											icon='fas fa-edit'
											title='Editar Preço Médio'
											classes='btn action-icon'
											onClick={() => {}}
											disabled={false}
										/>

										<Button
											icon='fas fa-plus'
											title='Mais detalhes do ativo'
											classes='btn action-icon'
											onClick={() => {}}
											disabled={false}
										/>
									</td>
								</tr>
							))}
					</tbody>
					<tfoot>
						<tr>
							<td>Totais</td>
							<td></td>
							<td></td>
							<td>
								{carteira.reduce((sum, record) => sum + record.quantidade, 0)}
							</td>
							<td></td>
							<td></td>
							<td>Vl cot</td>
							<td>
								{formataMoeda(
									carteira.reduce((sum, record) => sum + record.valorTotalPM, 0)
								)}
							</td>
						</tr>
					</tfoot>
				</table>
			</SimpleBar>
		</div>
	);
};

TableCarteiraRendaVariavel.propTypes = {
	carteira: PropTypes.array.isRequired
};

export default connect(null, { getCotacaoAtivo })(
	TableCarteiraRendaVariavel
);
