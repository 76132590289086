import React from 'react';
import PropTypes from 'prop-types';

const Card = (props) => {
	const { children, classes } = props;
	// shadow
	return <div className={`card ${classes}`} {...props}>{children}</div>;
};

Card.propTypes = {
	classes: PropTypes.string
};

export default Card;
