import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';

import LogoBeganinha from '../../../components/common/SVGIcon';
import Button from '../../../components/form/Button';
import CustomMaskedTextInput from '../../../components/form/CustomMaskedTextInput';
import { isCpfValido } from '../../../../config/helper/utils';
import Row from '../../../components/common/Row';
import { esqueciMinhaSenha } from '../action/AuthAction';

const EsqueciSenha = (props) => {
	const { esqueciMinhaSenha, history } = props;

	const [INITIAL_VALUES] = useState({
		cpf: ''
	});

	const validationSchema = Yup.object({
		cpf: Yup.string().test('CPF-valido', 'CPF Inválido', (value) =>
			isCpfValido(value)
		)
	});

	const onSubmitEsqueciSenha = (values, { setSubmitting, resetForm }) => {
		esqueciMinhaSenha(values.cpf)
			.then(() => {
				swal({
					title: 'Tudo certo!',
					text: 'Enviamos o código de verificação para teu e-mail',
					icon: 'success',
					buttons: {
						ok: {
							text: 'Ok',
							className: 'btn-primary'
						}
					}
				}).then(() => {
					history.push('/nova-senha-codigo');
				});
			})
			.catch((err) => {
				swal({
					title: 'Ops',
					text:
						'Algo deu errado e não conseguimos te enviar um novo código. Tente novamente mais tarde.',
					icon: 'warning',
					buttons: {
						ok: {
							text: 'Ok',
							className: 'btn-primary'
						}
					}
				});
				setSubmitting(false);
				resetForm();
			});
	};

	return (
		<Row>
			<div className='col-lg-6 d-none d-lg-block bg-esqueci-senha-image'></div>
			<div className='col-lg-6'>
				<div className='p-5'>
					<div className='d-flex justify-content-center py-3'>
						<Link to='/home'>
							<LogoBeganinha name='be_preto' classNameCustom='logo-be' />
						</Link>
					</div>
					<div className='text-center'>
						<h1 className='h4 py-3'>Esqueci minha senha</h1>
					</div>
					<p className='lead'>
						Digite teu CPF para validarmos tua conta e te enviarmos um código de
						verificação.
					</p>
					<Formik
						initialValues={INITIAL_VALUES}
						validationSchema={validationSchema}
						onSubmit={onSubmitEsqueciSenha}
					>
						{({ handleSubmit, isSubmitting }) => (
							<Form className='form-be-custom' onSubmit={handleSubmit}>
								<div className='form-group'>
									<CustomMaskedTextInput
										name='cpf'
										type='tel'
										placeholder='CPF'
										mask={[
											/[0-9]/,
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/
										]}
										className='custom-form-control'
									/>
								</div>
								<Button
									classes='btn btn-secondary btn-block rounded-pill'
									texto='Enviar código de verificação'
									disabled={isSubmitting}
								/>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Row>
	);
};

export default compose(
	withRouter,
	connect(null, { esqueciMinhaSenha })
)(EsqueciSenha);
