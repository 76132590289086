import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  setShowModalLancamentos,
  setDataContextLancamentos,
} from "./action/ArrumandoCasaAction";
import { carregarUsuario } from "../../../minimal/autenticacao/action/AuthAction";
import Title from "../../../components/common/Title";
import ListaLancamento from "./ListaLancamento";
import ModalAddUpdateLancamento from "./ModalAddUpdateLancamento";
import Button from "../../../components/form/Button";
import Row from "../../../components/common/Row";
// import HeaderBotoesMeses from "./HeaderBotoesMeses";
import HeaderBotoesMeses from "../../../components/common/HeaderBotoesMeses";
import Card from "../../../components/common/Card";
import CardBody from "../../../components/common/CardBody";
import Erro500 from "../../../components/common/Erro500";

const MeusLancamentos = (props) => {
  const {
    setShowModalLancamentos,
    isErroGetLancamentos,
    erroLancamentos,
    carregarUsuario,
    erroAutenticacao,
    setDataContextLancamentos,
    dataContextLancamentos,
  } = props;

  useEffect(() => {
    // setLoading(true);
    carregarUsuario()
      .then(() => {
        // console.log("carregaUsuario - meus lancamentos")
        // setLoading(false);
      })
      .catch((err) => {
        // setLoading(false);
        // setErroAutenticacao(err);
      });
    // eslint-disable-next-line
  }, []);

  if (erroAutenticacao !== null) {
    return <Redirect to="/login" />;
  } else if (isErroGetLancamentos && erroLancamentos !== null) {
    return <Erro500 erro={erroLancamentos} />;
  }

  return (
    <>
      <Title
        text="Meus lançamentos"
        textTooltip={`Aqui você lança quanto você recebeu, quanto gastou com contas essenciais e 
								não essenciais e quanto investiu. Com essas informações, faremos uma análise da vida financeira, de sugerindo metas de gastos e investimentos. 
								Também te apresentaremos um relatório detalhando mês a mês quanto você recebeu, gastou e investiu.`}
      />
      <Row>
        <div className="col-12">
          <Card>
            <CardBody classes="card-body-customheight">
              <Row>
                {/* <HeaderBotoesMeses tipoData='lancamentos' /> */}
                <HeaderBotoesMeses
                  functionName={setDataContextLancamentos}
                  dataRedux={dataContextLancamentos}
                />
              </Row>

              <ListaLancamento />
            </CardBody>
          </Card>
        </div>
      </Row>
      <Row classes="mt-3">
        <div className="col-12 text-right">
          <Button
            icon="fas fa-plus"
            texto="Novo Lançamento"
            classes="btn btn-primary"
            onClick={() => setShowModalLancamentos(true)}
            disabled={false}
          />
        </div>
      </Row>
      <ModalAddUpdateLancamento />
    </>
  );
};

const mapStateToProps = (state) => ({
  isErroGetLancamentos: state.arrumaCasaStore.isErroGetLancamentos,
  erroLancamentos: state.arrumaCasaStore.erroLancamentos,
  dataContextLancamentos: state.arrumaCasaStore.dataContextLancamentos,
  erroAutenticacao: state.authStore.erroAutenticacao,
});

export default connect(mapStateToProps, {
  setShowModalLancamentos,
  carregarUsuario,
  setDataContextLancamentos,
})(MeusLancamentos);
