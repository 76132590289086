import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { sendEmail } from './action/CommonAction';
import { ReactComponent as Startman } from '../../../assets/img/startman.svg';

const Erro500 = (props) => {
	const { sendEmail, erro } = props;

	const sendEmailProblema = (location, erro) => {
		const msg = {
			nome: 'Be - site',
			email: 'afonso@beganinha.com.br',
			mensagem: 'erro de conexão ao backend em: ' + location + ' <br><br> Erro: ' + JSON.stringify(erro) + '<br>',
			title: 'Tela Erro 500'
		};
		if (erro !== null && erro.code !== 'NotAuthorizedException') {
			sendEmail(msg);
		}		
	};

	useEffect(() => {
		sendEmailProblema(props.location.pathname, erro);

		// eslint-disable-next-line
	}, []);

	return (
		<div className='account-pages mt-5 mb-5'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-lg-5'>
						<div className='card'>
							<div className='card-body p-4'>
								<div className='text-center'>
									<Startman
										id='startman'
										className='logo-startman'
										viewBox='0 0 829 744'
									/>

									<h1 className='text-error mt-4'>500</h1>
									<h4 className='text-uppercase text-danger mt-3'>
										Não conseguimos acessar nossos servidores
									</h4>
									<p className='text-muted mt-3'>
										Tente acessar novamente, pois pode ter sido uma falha
										momentanea. De qualquer forma, já avisei nosso time de nerds
										programadores.
									</p>

									<button
										className='btn btn-info mt-3'
										onClick={() => window.location.reload(false)}
									>
										<i className='fas fa-redo mr-3'></i> Tentar novamente!
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Erro500.propTypes = {
	erro: PropTypes.any.isRequired
};

export default compose(withRouter, connect(null, { sendEmail }))(Erro500);
