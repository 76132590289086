import * as React from 'react';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

const Tooltip = ({ text, icon, trigger = 'hover', placement = 'auto-start' }) => {
	const {
		getArrowProps,
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible
	} = usePopperTooltip({
		trigger: trigger,
		placement: placement
	});

	return (
		<span className='ml-2'>
			<i className={icon} ref={setTriggerRef} />
			{visible && (
				<div
					ref={setTooltipRef}
					{...getTooltipProps({ className: 'tooltip-container' })}
				>
					<div {...getArrowProps({ className: 'tooltip-arrow' })} />
					{text}
				</div>
			)}
		</span>
	);
};

Tooltip.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	trigger: PropTypes.string,
	placement: PropTypes.string,
};

export default Tooltip;
