import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import moment from 'moment';

import { setShowModalConectemeCei, connectCei } from './action/UsuarioAction';
import Button from '../../../components/form/Button';
import Row from '../../../components/common/Row';
import CustomTextInput from '../../../components/form/CustomTextInput';
import CustomMaskedTextInput from '../../../components/form/CustomMaskedTextInput';
import CustomRadioInput from '../../../components/form/CustomRadioInput';
import CustomTextArea from '../../../components/form/CustomTextArea';
import CustomCheckbox from '../../../components/form/CustomCheckbox';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';

const ModalConectemeCei = (props) => {
	const {
		showModalConectemeCei,
		setShowModalConectemeCei,
		erroCountConexao,
		connectCei,
		autorizacaoCei,
		usuario,
		setErroAutenticacao
	} = props;

	const [INITIAL_VALUES] = useState({
		cpf: usuario.username,
		senha: '',
		tipoAutorizacao: '',
		autorizacao: false,
		dataAutorizacao: moment().utc().format()
	});

	const validationSchema = Yup.object({
		senha: Yup.string().required('A senha é obrigatória'),
		tipoAutorizacao: Yup.string().required('O tipo de importação é obrigatório'),
		autorizacao: Yup.boolean()
				.oneOf([true], "You must accept the terms and conditions"),
	});

	const closeUp = (resetForm) => {
		setShowModalConectemeCei(false);
		resetForm({});
	};

	const onSubmit = (values, { setSubmitting, resetForm }) => {
		connectCei(values, autorizacaoCei, erroCountConexao).then(() => {
			closeUp(resetForm);
			setSubmitting(false);
			
		}).catch((err) => {
			setSubmitting(false);
			if (err.code === 'NotAuthorizedException') {
				setErroAutenticacao(err);
			}			
			console.log(err)
		});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={INITIAL_VALUES}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, resetForm }) => (
				<div className='m-4'>
					<Modal
						open={showModalConectemeCei}
						onClose={() => closeUp(resetForm)}
						center
					>
						<Row classes='mt-4'>
							<div className='col-12 text-center'>
								<h3>Conecte-me ao CEI</h3>
							</div>
						</Row>
						<Row classes='mt-2'>
							<div className='col-12 text-center'>
								<p className='text-xs text-gray-700'>
									{`Conectando o Be ao CEI, importaremos as informações
									relacionadas a ações, FIIs, opções e tesouro direto
									automaticamente. `}
								</p>
							</div>
						</Row>
						<Form className='form-be-custom mt-3'>
							<Row classes='form-group pt-2'>
								<div className='col-12'>
									<CustomMaskedTextInput
										name='cpf'
										type='tel'
										placeholder='CPF'
										mask={[
											/[0-9]/,
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/
										]}
										className='custom-form-control-retangular'
										value={usuario.username}
										disabled
									/>
								</div>
							</Row>
							<Row classes='form-group pt-2'>
								<div className='col-12'>
									<CustomTextInput
										name='senha'
										type='password'
										placeholder='Senha do CEI'
										className='custom-form-control-retangular'
									/>
								</div>
							</Row>
							<Row classes='form-group pt-2'>
								<div className='col-12 mb-1'>
									<span className='font-weight-bold'>
										O que você quer importar?
									</span>
								</div>
								<div className='col-12'>
									<span>
										<CustomRadioInput
											name='tipoAutorizacao'
											label='Minha carteira com a última posição disponível'
											value='carteira'
											id='carteira'
										/>
									</span>
								</div>
								<div className='col-12'>
									<span>
										<CustomRadioInput
											name='tipoAutorizacao'
											label='Minha carteira e meu histórico de operações de compra e venda dos últimos 18 meses'
											value='historico'
											id='historico'
										/>
									</span>
								</div>
								{/* <div className='col-12'>
									<span>
										<CustomRadioInput
											name='tipoAutorizacao'
											label='Minha carteira, meu histórico de operações de compra e venda dos últimos 18 meses e meus proventos do mês'
											value='proventos'
											id='proventos'
										/>
									</span>
								</div> */}
							</Row>
							<Row classes='form-group pt-2'>
								<div className='col-12'>
										<CustomTextArea
											name='textoAutorizacao'
											className='custom-form-control-retangular'
											rows={3}
											value={`O processo de conexão demora um pouquinho para ser concluído, então te enviaremos um e-mail assim que finalizarmos a importação; \n O CEI disponibiliza as operações, em sua plataforma, apenas depois que estas foram liquidadas e isso pode levar até 2 dias após a negociação, e;\nPor questões de segurança, NÃO gravamos sua senha, então você deve fornecê-la sempre que precisar atualizar as informações do CEI. Porém não importaremos tudo novamente, apenas as atualizações que houverem na sua conta.\n\nI - Autorizo o Beganinha a acessar o CEI - Canal Eletrônico do Investidor (https://cei.b3.com.br) usando o meu CPF e a minha senha de acesso informados, por mim, nesta tela;\nII - Autorizo o Beganinha a acessar meus dados financeiros disponibilizados no sistema do CEI que mantém o histórico de movimentações de ativos na(s) conta(s) das instituições nas quais realizo operações.`}
											disabled={true}
										/>
								</div>
								<div className='col-12 mt-1'>
									<span className='font-weight-bold'>
										<CustomCheckbox
											name='autorizacao'
											label='Eu autorizo o Beganinha a importar meus dados do CEI'
											id='autorizacao'
										/>
									</span>
								</div>
							</Row>
							<Row classes=' pt-3'>
								<div className='col-12 text-right'>
									<Button
										classes='btn btn-secondary mr-3'
										texto='Cancelar'
										disabled={false}
										onClick={() => {
											closeUp(resetForm);
										}}
									/>
									<Button
										classes='btn btn-primary'
										texto='Conectar'
										disabled={isSubmitting}
										id='btn-salvar'
									/>
								</div>
							</Row>
						</Form>
					</Modal>
				</div>
			)}
		</Formik>
	);
};

const mapStateToProps = (state) => ({
	showModalConectemeCei: state.usuarioStore.showModalConectemeCei,
	erroCountConexao: state.usuarioStore.erroCountConexao,
	autorizacaoCei: state.usuarioStore.autorizacaoCei,
	usuario: state.authStore.usuario
});

export default connect(mapStateToProps, {
	setShowModalConectemeCei,
	connectCei,
	setErroAutenticacao
})(ModalConectemeCei);
