import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { setShowModalObjetivos } from './action/FazendoCaixaAction';
import { carregarUsuario } from '../../../minimal/autenticacao/action/AuthAction';
import Title from '../../../components/common/Title';
import Row from '../../../components/common/Row';
import Card from '../../../components/common/Card';
import CardBody from '../../../components/common/CardBody';
import ListaObjetivos from './ListaObjetivos';
import Button from '../../../components/form/Button';
import ModalAddUpdateObjetivo from './ModalAddUpdateObjetivo';
import ModalAnaliseObjetivo from './ModalAnaliseObjetivo';
import Erro500 from '../../../components/common/Erro500';

const MeusObjetivos = (props) => {
	const {
		setShowModalObjetivos,
		isErroGetObjetivos,
		erroObjetivos,
		erroAutenticacao,
		carregarUsuario
	} = props;

	useEffect(() => {
		// setLoading(true);
		carregarUsuario()
			.then(() => {
				// console.log("carregaUsuario - meus lancamentos")
				// setLoading(false);
			})
			.catch((err) => {
				// setLoading(false);
				// setErroAutenticacao(err);
			});
		// eslint-disable-next-line
	}, []);

	if (erroAutenticacao !== null) {
		return <Redirect to='/login' />;
	} else if (isErroGetObjetivos && erroObjetivos !== null) {
		return <Erro500 erro={erroObjetivos} />;
	}
	return (
		<>
			<Title
				text='Meus Objetivos'
				textTooltip={`Aqui você cadastra seus objetivos e nós te mostramos quanto você precisa guardar por mês para atingí-lo, assim você não terá nenhum problema para curtir seu sonho de vida!`}
			/>
			<Row>
				<div className='col-12'>
					<Card>
						<CardBody classes='card-body-customheight'>
							<ListaObjetivos />
						</CardBody>
					</Card>
				</div>
			</Row>
			<Row classes='mt-3'>
				<div className='col-12 text-right'>
					<Button
						icon='fas fa-plus'
						texto='Novo Objetivo'
						classes='btn btn-primary'
						onClick={() => setShowModalObjetivos(true)}
						disabled={false}
					/>
				</div>
			</Row>
			<ModalAddUpdateObjetivo />
			<ModalAnaliseObjetivo />
		</>
	);
};

const mapStateToPros = (state) => ({
	isErroGetObjetivos: state.fazendoCaixaStore.isErroGetObjetivos,
	erroObjetivos: state.fazendoCaixaStore.erroObjetivos,
	erroAutenticacao: state.authStore.erroAutenticacao
});

export default connect(mapStateToPros, {
	setShowModalObjetivos,
	carregarUsuario
})(MeusObjetivos);
