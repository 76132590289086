import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import swal from 'sweetalert';

import { alterarGuidedTour } from '../../../../layouts/minimal/autenticacao/action/AuthAction';
import { setSideBarToggle } from '../sidebar/action/SideBarAction';

const GuidedTour = (props) => {
	const { alterarGuidedTour, usuario, setSideBarToggle } = props;
	const [isTourOpen, setIsTourOpen] = useState(true);
	const steps = [
		{
			target: '.tour-passo-arrumando',
			content:
				`Tudo começa aqui, onde você nos diz quais seus recebimentos, seus gastos e investimentos. 
				Com isso conseguiremos fazer a análise financeira baseada na regra 50-30-20. 
				Também vamos te mostrar um calendário para que você tenha em mente os dias em que recebe e gasta seu dinheiro.`,
			title: 'Arrumando a casa'
		},
		{
			target: '.tour-passo-fazendo',
			content:
				'Depois você nos diz quais são seus sonhos e objetivos que vamos te ajudar a realizar.',
			title: 'Fazendo um bom caixa'
		},
		{
			target: '.tour-passo-investidor',
			content: `Aqui vamos lançar todas aplicações financeiras, te mostrar uma forma fácil de acompanhá-las e 
				vamos associá-las aos teus sonhos e objetivos. Vamos, também, te mostrar um calendário de recebimentos de proventos 
				para que você possa se programar.`,
			title: 'Investidor de sucesso'
		},
		{
			target: '.tour-passo-ninja',
			content: `Essa é a parte mais legal que temos! Você terá acesso a análises, recomendações de ativos financeiros de 
				forma simples e fácil de entender. Te prometemos!`,
			title: 'Aprendendo com os mestres'
		},
		{
			target: '.tour-passo-consolido',
			content: `Aqui você tem uma visão consolidada de todos seus recebimentos, gastos e investimentos.`,
			placement: 'bottom'
		},
		{
			target: '.tour-passo-grafico-recebimento-gastos',
			content: `Já aqui você tem uma visão que relaciona seus recebimentos, gastos e investimentos nos últimos 12 meses, 
			assim você poderá saber se seus gastos estão sobre controle e o mais importante, se você está ganhando mais! :)`
		},
		{
			target: '.tour-passo-atividades-recentes',
			content: `Tenha uma linha do tempo das suas atividades de gastos e recebimentos e fique por dentro da vida do seu dinheiro.`,
			// placement: 'top'
		},
		{
			target: '.tour-passo-grafico-carteira',
			content: `Quer saber a composição da sua carteira? Te mostramos de uma forma muito legal.`,
			placement: 'left'
		},
		{
			target: '.tour-passo-grafico-evolucao-patrimonial',
			content: `Acompanhe de perto como está sua evolução patrimonial mês a mês nos últimos 12 meses.`,
			placement: 'right'
		},
		{
			target: '.tour-passo-alertas',
			content: `Tudo o que você precisa saber sobre seu dinheiro será informado aqui.`,
			placement: 'bottom'
		},
		{
			target: '.tour-passo-usuario',
			content: `Quer alterar algum dado pessoal, ver suas timeline da riqueza e se conectar ao seu banco ou a sua corretora? Esse é o local..`,
			placement: 'bottom'
		}
	];
	const buttons = {
		naoMostrar: {
			text: 'Não precisa',
			value: 'naoMostrar',
			className: 'btn-secondary'
		},
		mostrar: {
			text: 'Me mostrar o Tour',
			className: 'btn-primary'
		}
	};

	const handleJoyrideCallback = (data) => {
		const { action, status } = data;

		if (
			[STATUS.FINISHED, STATUS.SKIPPED, STATUS.CLOSE].includes(status) ||
			[ACTIONS.CLOSE].includes(action)
		) {
			// Need to set our running state to false, so we can restart if we click start again.
			swal({
				title: 'Aviso',
				text: 'Devemos te mostrar esse Tour na próxima vez que você conectar?',
				icon: 'warning',
				buttons: buttons
			}).then((value) => {
				switch (value) {
					case 'naoMostrar': {
						alterarGuidedTour(usuario);
						break;
					}
					default:
						break;
				}
			});
			// setSideBarToggle('toggled');
			setIsTourOpen(false);
		}
	};

	useEffect(() => {
		setSideBarToggle('');
		// eslint-disable-next-line
	}, []);

	return (
		<Joyride
			steps={steps}
			callback={(data) => handleJoyrideCallback(data)}
			continuous={true}
			showSkipButton={true}
			styles={{
				tooltipContainer: {
					textAlign: 'left'
				},
				buttonNext: {
					backgroundColor: '#17BEBB'
				},
				buttonBack: {
					marginRight: 10
				}
			}}
			locale={{
				last: 'Finalizar',
				skip: 'Sair',
				back: 'Voltar',
				next: 'Próximo'
			}}
			run={isTourOpen}
		/>
	);
};

const mapStateToProps = (state) => ({
	usuario: state.authStore.usuario
});

export default connect(mapStateToProps, {
	alterarGuidedTour,
	setSideBarToggle
})(GuidedTour);
