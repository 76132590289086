import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import moment from 'moment';

import { setAtivoCurrent, submit } from '../action/MinhasOperacoesAction';
import CustomTextInput from '../../../../../components/form/CustomTextInput';
import Button from '../../../../../components/form/Button';
import CustomSelectOptionsInput from '../../../../../components/form/CustomSelectOptionsInput';
import CurrencyMaskedTextInput from '../../../../../components/form/CurrencyMaskedTextInput';
import CustomMaskedTextInput from '../../../../../components/form/CustomMaskedTextInput';
import Tooltip from '../../../../../components/common/Tooltip';
import {
	success,
	error,
	MSG_TOAST_ERRO_DE_NOVO
} from '../../../../../components/toast/action/ToastAction';
import Row from '../../../../../components/common/Row';
import { listaCorretora } from '../../../../../../config/helper/listaCorretora';
import { listaTipoAtivo } from '../../../../../../config/helper/listaTipoAtivo';
import { listaTipoOperacaoAtivo } from '../../../../../../config/helper/listaTipoOperacaoAtivo';

const ModalAddUpdateOperacaoAtivo = (props) => {
	const {
		success,
		error,
		showModalNovoAtivo,
		setAtivoCurrent,
		submit,
		erroCountInvestidorGeneric,
		operacao,
		instituicao
	} = props;

	const [optsCorretoras] = useState(
		listaCorretora.map((item) => {
			return {
				label: `${item.codigo !== '' ? item.codigo + ' - ' : ''} ${item.nome
					.toLowerCase()
					.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())}`,
				value: item.codigo
			};
		})
	);

	const [optsTiposAtivo] = useState(listaTipoAtivo);
	const [optsTipoOperacaoAtivo] = useState(listaTipoOperacaoAtivo);

	const [INITIAL_VALUES] = useState({
		instituicao: instituicao ? instituicao.instituicao : optsCorretoras[0],
		conta: instituicao ? instituicao.conta : '',
		descricao: instituicao ? instituicao.descricao : '',
		operacoes: [
			{
				tipoAtivo: listaTipoAtivo[0],
				tipoOperacaoAtivo: listaTipoOperacaoAtivo[0],
				codigo: '',
				nome: '',
				quantidade: '',
				dataOperacao: '',
				valorUnitario: '',
				taxas: 0,
				valorTotal: ''
			}
		]
	});

	const operacaoForm = () => {
		return {
			...instituicao,
			operacoes: [operacao]
		}
	};

	const validationSchema = Yup.object({
		instituicao: Yup.object()
			.test('Tipo Value', 'A corretora é obrigatória', (val) => {
				if (typeof val.value === 'undefined') {
					return false;
				} else {
					return true;
				}
			})
			.required(),
		conta: Yup.string().required('O número da corretora é obrigatório'),
		operacoes: Yup.array().of(
			Yup.object().shape({
				tipoAtivo: Yup.object()
					.test('Tipo Value', 'O tipo do ativo é obrigatório', (val) => {
						if (
							typeof val !== 'undefined' &&
							typeof val.value === 'undefined'
						) {
							return false;
						} else {
							return true;
						}
					})
					.required('O tipo do ativo é obrigatório'),
				tipoOperacaoAtivo: Yup.object()
					.test(
						'Tipo Value',
						'O tipo da operação do ativo é obrigatório',
						(val) => {
							if (
								typeof val !== 'undefined' &&
								typeof val.value === 'undefined'
							) {
								return false;
							} else {
								return true;
							}
						}
					)
					.required('O tipo da operação do ativo é obrigatório'),
				codigo: Yup.string().required('O código do ativo é obrigatório'),
				nome: Yup.string().required('O nome do ativo é obrigatório'),
				quantidade: Yup.string()
					.required('A quantidade do ativo é obrigatório')
					.test('Tipo Value', 'A quantidade deve ser maior que zero', (val) => {
						if (val <= 0) {
							return false;
						} else {
							return true;
						}
					}),
				valorUnitario: Yup.string()
					.required('O valor do ativo é obrigatório')
					.test(
						'Tipo Value',
						'O valor unitário deve ser maior que zero',
						(val) => {
							if (val <= 0) {
								return false;
							} else {
								return true;
							}
						}
					),
				dataOperacao: Yup.string()
					.required('A data da operação é obrigatória')
					.test('data-valida', 'Data da operação inválida', (value) => {
						return moment(value, 'DD/MM/YYYY').isValid();
					})
					.test(
						'data-maior',
						'A data da operação é maior do que a data de hoje',
						(value) => {
							let data = moment(value, 'DD/MM/YYYY');
							return moment(new Date(), 'DD/MM/YYYY').isAfter(data);
						}
					)
			})
		)
	});

	const renderFieldsOperacoesAtivo = (item, index) => (
		<>
			<Row classes='mb-3 form-group'>
				<div className='col-sm-4 mb-sm-0'>
					<label>Tipo do Ativo</label>
					<Tooltip
						text={`Para montar sua carteira, precisamos saber é um ativo de renda fixa ou de renda variável.`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
					<CustomSelectOptionsInput
						name={`operacoes[${index}].tipoAtivo`}
						className='custom-form-control-retangular'
						options={optsTiposAtivo}
						placeholder={optsTiposAtivo[0].label}
						value={item.tipoAtivo}
						isDisabled={operacao ? true : false}
					/>
				</div>
				<div className='col-sm-4 mb-sm-0'>
					<label>Tipo da operação do Ativo</label>
					<Tooltip
						text={`Para montar sua carteira, precisamos saber se é uma operação de compra ou de venda de um ativo.`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
					<CustomSelectOptionsInput
						name={`operacoes[${index}].tipoOperacaoAtivo`}
						className='custom-form-control-retangular'
						options={optsTipoOperacaoAtivo}
						placeholder={optsTipoOperacaoAtivo[0].label}
						value={item.tipoOperacaoAtivo}
						isDisabled={operacao ? true : false}
					/>
				</div>
				<div className='col-sm-4'>
					<label className='mr-2'>Código do Ativo</label>
					<Tooltip
						text={`O código do ativo é usado para identificá-lo em futuras consultas. Caso seja um ativo de renda fixa, 
							você pode escolher o código que melhor se encaixa ao tipo do ativo. Por exemplo: Para um CDB, o código pode ser CDB001. Já para operacoes de renda variável, o código é o
							TICKER do ativo.`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
					<CustomTextInput
						name={`operacoes[${index}].codigo`}
						type='text'
						placeholder='Ex.: Tesouro-IPCA ou ITSA4'
						className='custom-form-control-retangular'
						size='10'
						value={typeof item.codigo !== "undefined" ? item.codigo.toUpperCase() : ''}
						disabled={operacao ? true : false}
					/>
				</div>
			</Row>
			<Row classes='form-group'>
				<div className='col-sm-8 mb-sm-0'>
					<label className='mr-2'>Nome do Ativo</label>
					<Tooltip
						text={`O nome do ativo é usado como complemento na sua identificação em futuras consultas. Caso seja um ativo de renda fixa, 
							você pode escolher o código que melhor se encaixa ao tipo do ativo. Por exemplo: Para um CDB, o código pode ser CDB Banco XPTO. Já para operacoes de renda variável, o nome é o
							nome da empresa ou do FII.`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
					<CustomTextInput
						name={`operacoes[${index}].nome`}
						type='text'
						placeholder='Ex.: Tesouro Direto IPCA ou Itaúsa SA '
						className='custom-form-control-retangular'
						size='10'
						value={item.nome}
						disabled={operacao ? true : false}
					/>
				</div>

				<div className='col-sm-4 mb-sm-0'>
					<label className='mr-2'>Data da operação</label>
					<CustomMaskedTextInput
						name={`operacoes[${index}].dataOperacao`}
						type='tel'
						mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
						placeholder='DD/MM/AAAA'
						className='custom-form-control-retangular'
						value={item.dataOperacao}
					/>
				</div>
			</Row>
			<Row classes='form-group'>
				<div className='col-sm-2 mb-sm-0'>
					<label className='mr-2'>Quantidade</label>
					<Tooltip
						text={`A quantidade pode ser um valor decimal caso o ativo seja um Tesouro Direto.`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
					<CustomTextInput
						name={`operacoes[${index}].quantidade`}
						type='number'
						placeholder='Ex.: 000000000'
						className='custom-form-control-retangular'
						value={item.quantidade}
					/>
				</div>
				<div className='col-sm-1 mb-sm-0 d-flex align-items-center'>
					<i className='fas fa-1x fa-times'></i>
				</div>
				<div className='col-sm-2 mb-sm-0'>
					<label className='mr-2'>Valor do ativo</label>
					<Tooltip
						text={`O valor únitário`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
					<CurrencyMaskedTextInput
						name={`operacoes[${index}].valorUnitario`}
						type='text'
						className='custom-form-control-retangular'
						size='10'
						value={item.valorUnitario}
					/>
				</div>
				<div className='col-sm-1 mb-sm-0 d-flex align-items-center'>
					<i className='fas fa-1x fa-plus'></i>
				</div>
				<div className='col-sm-2 mb-sm-0'>
					<label className='mr-2'>Taxas</label>
					<Tooltip
						text={`O valor únitário`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
					<CurrencyMaskedTextInput
						name={`operacoes[${index}].taxas`}
						type='text'
						className='custom-form-control-retangular'
						size='10'
						value={item.taxas}
					/>
				</div>
				<div className='col-sm-1 mb-sm-0 d-flex align-items-center'>
					<i className='fas fa-1x fa-equals'></i>
				</div>
				<div className='col-sm-3'>
					<label className='mr-2'>Valor total da operação</label>
					<CurrencyMaskedTextInput
						name={`operacoes[${index}].valorTotal`}
						type='text'
						className='custom-form-control-retangular'
						size='10'
						isDisabled={true}
						value={
							(item.valorUnitario * item.quantidade) + item.taxas
						}
					/>
				</div>
			</Row>
		</>
	);

	const renderButtonsFieldsOperacoesAtivo = (remove, push, index) => {
		return (
			operacao === null && (
				<>
					<Row>
						<div className='col-12 text-center mb-3'>
							{index > 0 && (
								<Button
									classes='btn btn-secondary'
									texto=''
									icon='fa fa-minus'
									id='btn-minus'
									onClick={() => remove(index)}
									disabled={false}
								/>
							)}
						</div>
						{/* </Row>
			<Row> */}
						<div className='col-12 text-center'>
							<Button
								classes='btn btn-primary'
								texto=''
								icon='fa fa-plus'
								id='btn-add'
								onClick={() => push('')}
								disabled={false}
							/>
						</div>
					</Row>
				</>
			)
		);
	};

	const renderFieldArrayOperacoesAtivos = () => (
		<div className='col-12'>
			<FieldArray name='operacoes'>
				{(fieldArrayProps) => {
					//pegar o form.values
					//push
					//remove
					const { push, remove, form } = fieldArrayProps;
					const { values } = form;
					const { operacoes } = values;
					return (
						<div>
							{operacoes.map((item, index) => (
								<div key={index}>
									<Row>
										<div className='col-11'>
											{renderFieldsOperacoesAtivo(item, index)}
										</div>
										<div className='col-1 d-flex align-items-center'>
											{renderButtonsFieldsOperacoesAtivo(remove, push, index)}
										</div>
									</Row>
									<hr />
								</div>
							))}
						</div>
					);
				}}
			</FieldArray>
		</div>
	);

	const onSubmit = (values, { setSubmitting, resetForm }) => {
		// console.log(JSON.stringify(values));
		submit(values)
			.then(() => {
				success();
				setSubmitting(false);
				cleanUp(resetForm);
			})
			.catch((err) => {
				if (erroCountInvestidorGeneric >= 1) {
					error(MSG_TOAST_ERRO_DE_NOVO);
				} else {
					error();
				}
				setSubmitting(false);
			});
	};

	const cleanUp = (resetForm) => {
		setAtivoCurrent(null, false);
		resetForm({});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={operacao ? operacaoForm() : INITIAL_VALUES}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onClose={() => setAtivoCurrent(null, false)}
		>
			{({ values, isSubmitting, resetForm }) => (
				<Modal
					open={showModalNovoAtivo}
					onClose={() => {
						cleanUp(resetForm);
					}}
					center
					classNames={{
						modal: 'modal-width'
					}}
				>
					<Row classes='my-3 modal-header'>
						<div className='col-12 text-center'>
							<h3>{operacao ? `Alterar` : `Novo`} ativo financeiro</h3>
						</div>
					</Row>
					<Form className='form-be-custom'>
						<Row classes='form-group'>
							<div className='col-12'>
								<CustomSelectOptionsInput
									name='instituicao'
									className='custom-form-control-retangular'
									options={optsCorretoras}
									placeholder={optsCorretoras[0].label}
									value={values.instituicao}
									isDisabled={instituicao ? true : false}
								/>
							</div>
						</Row>
						<Row classes='form-group'>
							<div className='col-sm-6 mb-3 mb-sm-0'>
								<label>Número da conta</label>
								<CustomTextInput
									name='conta'
									type='text'
									placeholder='Ex.: 000000000'
									className='custom-form-control-retangular'
									disabled={instituicao ? true : false}
								/>
							</div>
							<div className='col-sm-6'>
								<label>Descrição da Corretora</label>
								<CustomTextInput
									name='descricao'
									type='text'
									placeholder='Ex.: Corretora para investimentos no Brasil.'
									className='custom-form-control-retangular'
									size='100'
									disabled={instituicao ? true : false}
								/>
							</div>
						</Row>
						<Row>{renderFieldArrayOperacoesAtivos()}</Row>
						<Row>
							<div className='col-12 text-right modal-footer'>
								<Button
									classes='btn btn-secondary mr-3'
									texto='Cancelar'
									disabled={false}
									onClick={() => {
										cleanUp(resetForm);
									}}
								/>
								<Button
									classes='btn btn-primary'
									texto='Salvar'
									disabled={isSubmitting}
									id='btn-salvar'
								/>
							</div>
						</Row>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

const mapStateToProps = (state) => ({
	showModalNovoAtivo: state.operacoesStore.showModalNovoAtivo,
	operacao: state.operacoesStore.operacao,
	instituicao: state.operacoesStore.instituicao,
	erroCountInvestidorGeneric: state.operacoesStore.erroCountInvestidorGeneric
});

export default connect(mapStateToProps, {
	success,
	error,
	setAtivoCurrent,
	submit
})(ModalAddUpdateOperacaoAtivo);
