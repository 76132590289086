import {
	GET_MINHAS_OPERACOES_BY_SK,
	GET_MINHAS_INSTITUICOES,
	SET_SHOW_MODAL_NOVO_ATIVO,
	ADD_OPERACOES,
	UPDATE_OPERACOES,
	ERROR_GENERIC,
	SET_INSTITUICAO,
	SET_LOADING_GENERIC,
	REMOVE_OPERACOES,
	SET_ATIVO_OPERACAO
} from './types';

const INITIAL_STATE = {
	operacoesRF: null,
	operacoesRV: null,
	instituicoes: null,
	instituicao: null,
	operacao: null,
	proventos: null,
	isGetOperacoes: false,
	isGetInstituicoes: false,
	loadingInvestidorGeneric: true,
	erroInvestidorGeneric: null,
	erroCountInvestidorGeneric: 0,
	erroGetInvestidorGeneric: false,
	showModalNovoAtivo: false,
	showModalOperacoes: false,
	showModalProventos: false
};

export const minhasOperacoesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_MINHAS_OPERACOES_BY_SK: {
			return {
				...state,
				operacoesRV: action.payload[0],
				operacoesRF: action.payload[1],
				isGetOperacoes: false,
				erroInvestidorGeneric: null,
				erroGetInvestidorGeneric: false,
				loadingInvestidorGeneric: false
			};
		}
		case GET_MINHAS_INSTITUICOES: {
			return {
				...state,
				instituicoes: action.payload,
				operacoesRF: [],
				operacoesRV: [],
				erroInvestidorGeneric: null,
				erroGetInvestidorGeneric: false,
				loadingInvestidorGeneric: false
			};
		}
		case SET_SHOW_MODAL_NOVO_ATIVO: {
			return {
				...state,
				showModalNovoAtivo: action.payload,
				loadingInvestidorGeneric: false
			};
		}
		case ERROR_GENERIC: {
			const hasCount = action.payload[2] && ++state.erroCountInvestidorGeneric;
			return {
				...state,
				erroInvestidorGeneric: action.payload[0],
				erroGetInvestidorGeneric: action.payload[1],
				erroCountInvestidorGeneric: hasCount,
				loadingInvestidorGeneric: false
			};
		}
		case ADD_OPERACOES: {
			// const newInstituicoes = [action.payload, ...state.instituicoes];
			// var setObj = new Set(); // create key value pair from array of array

			// var result = newInstituicoes.reduce((acc, item) => {
			// 	if (!setObj.has(item.sk)) {
			// 		setObj.add(item.sk, item);
			// 		acc.push(item);
			// 	}
			// 	return acc;
			// }, []);


			return {
				...state,
				// operacoesRV: [action.payload[0], ...state.operacoesRV],
				// operacoesRF: [action.payload[1], ...state.operacoesRF],
				isGetOperacoes: true,
				isGetInstituicoes: true,
				operacao: null,
				loadingInvestidorGeneric: false,
				erroInvestidorGeneric: null,
				erroCountInvestidorGeneric: 0
			};
		}
		case UPDATE_OPERACOES: {
			return {
				...state,
				operacao: null,
				isGetOperacoes: true,
				isGetInstituicoes: true,
				loadingInvestidorGeneric: false,
				erroInvestidorGeneric: null,
				erroCountInvestidorGeneric: 0
			};
		}
		case REMOVE_OPERACOES: {
			return {
				...state,
				operacao: null,
				isGetOperacoes: true,
				loadingInvestidorGeneric: false,
				erroInvestidorGeneric: null,
				erroCountInvestidorGeneric: 0
			};
		}
		case SET_INSTITUICAO: {
			return {
				...state,
				instituicao: action.payload
			};
		}
		case SET_ATIVO_OPERACAO: {
			return {
				...state,
				operacao: action.payload
			};
		}
		case SET_LOADING_GENERIC: {
			return {
				...state,
				loadingInvestidorGeneric: action.payload
			};
		}
		default:
			return state;
	}
};
