import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Title from '../../../components/common/Title';
import Row from '../../../components/common/Row';
import RowCardObjetivos from './RowCardObjetivos';
import Erro500 from '../../../components/common/Erro500';

const MinhaEvolucaoObjetivos = (props) => {

	const { isErroGetObjetivos, erroObjetivos, erroAutenticacao } = props;

	if (erroAutenticacao !== null) {
		return <Redirect to='/login' />;
	} else if (isErroGetObjetivos && erroObjetivos !== null) {
		return <Erro500 erro={erroObjetivos} />;
	}

	return (
		<>
			<Title
				text='Minha evolução'
				textTooltip={`Te mostramos como estão seus objetivos, quanto falta para você alcançá-los e inclusive te mostramos quais lançamentos você fez e quando você os fez.`}
			/>
			{/* <Row>
				<div className='col-12'>
					<p className='h5 font-weight-bold text-center'>Veja o andamento de todos seus ob</p>
				</div>
			</Row> */}
			<Row>
				<div className='col-12'>
					<RowCardObjetivos />
				</div>
			</Row>
		</>
	);
};


const mapStateToPros = (state) => ({
	isErroGetObjetivos: state.fazendoCaixaStore.isErroGetObjetivos,
	erroObjetivos: state.fazendoCaixaStore.erroObjetivos,
	erroAutenticacao: state.authStore.erroAutenticacao
})

export default connect(mapStateToPros)(MinhaEvolucaoObjetivos);
