import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';

import {
	setWidth,
	formataMoeda,
	getMes
} from '../../../../config/helper/utils';
import Spinner from '../../../components/preLoader/Spinner';
import Row from '../../../components/common/Row';
import Card from '../../../components/common/Card';
import CardHeader from '../../../components/common/CardHeader';
import CardBody from '../../../components/common/CardBody';
import Tooltip from '../../../components/common/Tooltip';

const GraficoRecebimentosGastos = (props) => {
	const {
		sideBarToggle,
		arrayValoresMesesGastos,
		arrayValoresMesesRecebimentos,
		arrayValoresMesesDesejos,
		arrayValoresMesesInvestimentos,
		height,
		loading
	} = props;

	const getMesPorExtenso = (data) => {
		let dataSplit = data.split('/');
		return getMes(parseInt(dataSplit[1] - 1)) + '/' + dataSplit[2];
		//Tá vindo com o valor do mês somado do BE, portando subtrai-se agora para funcionar a lógica do getMes
	};

	const handleSerie = () => {
		return [
			{
				name: 'Recebimentos',
				data:
					arrayValoresMesesRecebimentos !== null
						? arrayValoresMesesRecebimentos.map((item) => item.valor)
						: []
			},
			{
				name: 'Gastos essenciais',
				data:
					arrayValoresMesesGastos !== null
						? arrayValoresMesesGastos.map((item) => item.valor)
						: []
			},
			{
				name: 'Gastos não essenciais',
				data:
					arrayValoresMesesDesejos !== null
						? arrayValoresMesesDesejos.map((item) => item.valor)
						: []
			},
			{
				name: 'Investimentos',
				data:
					arrayValoresMesesInvestimentos !== null
						? arrayValoresMesesInvestimentos.map((item) => item.valor)
						: []
			}
		];
	};

	const handleOptions = {
		chart: {
			height: 364,
			type: 'line',
			dropShadow: {
				enabled: true,
				opacity: 0.1,
				blur: 7,
				left: -7,
				top: 7
			},
			toolbar: {
				show: false
			},
			parentHeightOffset: 0
		},
		grid: {
			padding: {
				left: 0,
				right: 0
			}
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: 'smooth',
			width: 4
		},
		zoom: {
			enabled: false
		},
		legend: {
			show: true
		},
		colors: ['#727cf5', '#ff5a5f', '#dc3545', '#0acf97'], //recebimento, gasto, desejos e investimento
		xaxis: {
			type: 'string',
			categories:
				arrayValoresMesesInvestimentos !== null
					? arrayValoresMesesInvestimentos.map((item) =>
							getMesPorExtenso(item.data)
					  )
					: [],
			tooltip: {
				enabled: false
			},
			axisBorder: {
				show: false
			}
		},
		yaxis: {
			labels: {
				formatter: function (val = 0) {
					// if (typeof val !== 'undefined') {
					return formataMoeda(val);
				}
			}
		}
	};

	// if (loadingLancamentos) {
	// 	return <Spinner id='spinner-centro-sm' />;
	// }

	return (
		!loading && (
			<Card classes='mb-4 tour-passo-grafico-recebimento-gastos'>
				<CardHeader classes='d-flex flex-row align-items-center'>
					<h6 className='m-0 font-weight-bold text-primary'>
						Análise dos valores por tipo de lançamento nos últimos 12 meses
					</h6>
					<Tooltip
						text={`O gráfico mostra o somatório dos últimos 12 meses dos valores categorizados por tipo de lançamento (recebimentos, gasto essencial, gasto não essencial e investimento).`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
					{/* <div className='dropdown no-arrow'>
					<a
						className='dropdown-toggle'
						href='recGastos'
						role='button'
						id='dropdownMenuLink'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'
					>
						<i className='fas fa-ellipsis-v fa-sm fa-fw text-gray-400'></i>
					</a>
					<div
						className='dropdown-menu dropdown-menu-right shadow animated--fade-in'
						aria-labelledby='dropdownMenuLink'
					>
						<div className='dropdown-header'>Ações: </div>
						<a className='dropdown-item' href='detalhes'>
							Detalhes
						</a>
					</div>
				</div> */}
				</CardHeader>
				{/* <!-- Card Body --> */}
				<CardBody>
					{arrayValoresMesesRecebimentos === null ? (
						<Spinner id='spinner-centro-sm' />
					) : (
						<>
							<div className='chart-content-bg'>
								<Row classes='text-center'>
									<div className='col-md-3 d-none d-md-block'>
										<p className='text-muted mb-0 mt-3'>Recebimentos</p>
										<h4 className='font-weight-normal mb-3'>
											<small className='text-primary align-middle mr-1'></small>
											<span className='h5'>
												{formataMoeda(
													arrayValoresMesesRecebimentos !== null &&
														arrayValoresMesesRecebimentos.reduce(
															(sum, record) => sum + record.valor,
															0
														)
												)}
											</span>
										</h4>
									</div>
									<div className='col-md-3 d-none d-md-block'>
										<p className='text-muted mb-0 mt-3'>Gastos essenciais</p>
										<h4 className='font-weight-normal mb-3'>
											<small className='text-success align-middle mr-1'></small>
											<span className='h5'>
												{formataMoeda(
													arrayValoresMesesGastos !== null &&
														arrayValoresMesesGastos.reduce(
															(sum, record) => sum + record.valor,
															0
														)
												)}
											</span>
										</h4>
									</div>
									<div className='col-md-3 d-none d-md-block'>
										<p className='text-muted mb-0 mt-3'>
											Gastos não essenciais
										</p>
										<h4 className='font-weight-normal mb-3'>
											<small className='text-success align-middle mr-1'></small>
											<span className='h5'>
												{formataMoeda(
													arrayValoresMesesDesejos !== null &&
														arrayValoresMesesDesejos.reduce(
															(sum, record) => sum + record.valor,
															0
														)
												)}
											</span>
										</h4>
									</div>
									<div className='col-md-3 d-none d-md-block'>
										<p className='text-muted mb-0 mt-3'>Investimentos</p>
										<h4 className='font-weight-normal mb-3'>
											<small className='text-success align-middle mr-1'></small>
											<span className='h5'>
												{formataMoeda(
													arrayValoresMesesInvestimentos !== null &&
														arrayValoresMesesInvestimentos.reduce(
															(sum, record) => sum + record.valor,
															0
														)
												)}
											</span>
										</h4>
									</div>
								</Row>
							</div>
							<div id='recebimento-gastos-chart' className='apex-charts'>
								<Chart
									options={handleOptions}
									series={handleSerie()}
									type='line'
									height={height}
									width={setWidth(sideBarToggle)}
									className='apex-charts mt-3'
								/>
							</div>
						</>
					)}
				</CardBody>
			</Card>
		)
	);
};

GraficoRecebimentosGastos.propTypes = {
	height: PropTypes.number
};

const mapStateToProps = (state) => ({
	sideBarToggle: state.sideBarStore.sideBarToggle,
	arrayValoresMesesRecebimentos:
		state.arrumaCasaStore.arrayValoresMesesRecebimentos,
	arrayValoresMesesGastos: state.arrumaCasaStore.arrayValoresMesesGastos,
	arrayValoresMesesDesejos: state.arrumaCasaStore.arrayValoresMesesDesejos,
	arrayValoresMesesInvestimentos:
		state.arrumaCasaStore.arrayValoresMesesInvestimentos,
	loading: state.commonStore.loading
});

export default connect(mapStateToProps)(GraficoRecebimentosGastos);
