import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import Sidebar from './dashboard/sidebar/Sidebar';
import Navbar from './dashboard/navbar/Navbar';
import ScrollTo from '../components/common/ScrollTo';
import GuidedTour from './dashboard/tour/GuidedTour';
import CloseButtonToast from '../components/toast/CloseButton';
import '../../assets/css/dashboard.css';
import 'react-toastify/dist/ReactToastify.min.css';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const PrivateLayout = (props) => {
	const { children, usuario } = props;
	const [width, setWidth] = useState(window.innerWidth);

	const update = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', update);
	}, [width]);

	return (
		<>
			<Helmet>
				<style type='text/css'>
					{`
						body, html {
							height: 100%;
						}
					`}
				</style>
			</Helmet>
			<div id='wrapper'>
				<Sidebar />

				{/* <!-- Content Wrapper --> */}
				<div id='content-wrapper' className='d-flex flex-column'>
					{/* <!-- Main Content --> */}
					<div id='content'>
						<Navbar />
						{/* <!-- Begin Page Content --> */}
						<div className='container-fluid'>{children}</div>
					</div>
				</div>
			</div>

			<ToastContainer closeButton={<CloseButtonToast />} draggable style={{ width: "35%" }}  />
			<ScrollTo onde='#sidebar_id' />
			{usuario !== null &&
				usuario.attributes['custom:isMostrarGuidedTour'] === '1' &&
				width >= 1024 && <GuidedTour />}
		</>
	);
};

const mapStateToProps = (state) => ({
	usuario: state.authStore.usuario
});

// const mapDispatchToProps = (dispatch) =>
// 	bindActionCreators(
// 		{
// 			carregarUsuario
// 		},
// 		dispatch
// 	);

export default connect(mapStateToProps)(PrivateLayout);
// export default PrivateLayout;
