import React from 'react';
import { Link } from 'react-router-dom';

const NaoEncontrado = () => {
	return (
		<div className='margin-nao-encontrado-page'>
			<div className='text-center py-5 my-4'>
				<div className='error mx-auto' data-text='404'>
					404
				</div>
				<p className='lead text-gray-800 mb-5'>Página não encontrada</p>
				<p className='text-gray-500 mb-0'>
					Parece que você encontrou uma falha na matrix ...
				</p>
				<Link to='/home'>&larr; Voltar</Link>
			</div>
		</div>
	);
};

export default NaoEncontrado;
