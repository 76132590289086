import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import If from './If';

const ProgressBar = ({ done }) => {
	const [style, setStyle] = useState({});

	useEffect(() => {
		
		setTimeout(() => {
			const newStyle = {
				opacity: 1,
				width: `${done}%`,
				justifyContent: 'space-evenly'
			};
			setStyle(newStyle);
		}, 200);

		// eslint-disable-next-line
	}, []);

	return (
		<div className='form-be-custom-progress'>
			<div className='form-be-custom-progress-done' style={style}>
				<div className='pl-5'>
					<If test={done > 0}>{done}%</If>
				</div>
			</div>
		</div>
	);
};

ProgressBar.propTypes = {
	done: PropTypes.number.isRequired
};

export default ProgressBar;
