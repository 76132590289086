import React from 'react'

const Tabs = (props) => {
    const { children, classes } = props;
    
    return (
        <ul className={`nav nav-pills ${classes} mb-3`}>
            {children}
        </ul>
    )
}

export default Tabs
