import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CardValueWithLeftColor from '../../../components/common/CardValueWithLeftColor';

const CardQuantoGastei = (props) => {
	const {
		lancamentos,
		loading,
		title = 'Meus gastos essenciais'
	} = props;

	const [totalGastos, setTotalGastos] = useState(0);

	useEffect(() => {
		setTotalGastos(
			lancamentos &&
				lancamentos.length > 0
				? lancamentos
						.filter((item) => item.tipoLancamento.value === 'gasto')
						.reduce((sum, record) => sum + record.valor, 0)
				: 0
		);

		// eslint-disable-next-line
	}, [lancamentos]);

	return (
		!loading && (
			<CardValueWithLeftColor
				title={title}
				leftColor='border-left-secondary'
				countup={false}
				icon='far fa-credit-card'
				// icon='fas fa-arrow-up'
				value={totalGastos}
			/>
		)
	);
};

CardQuantoGastei.propTypes = {
	title: PropTypes.string
};

const mapStateToProps = (state) => ({
	lancamentos: state.arrumaCasaStore.lancamentos,
	loading: state.commonStore.loading,
});

export default connect(mapStateToProps)(CardQuantoGastei);
