import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import CardWidget from '../../../components/common/CardWidget';
import { calculaVariacao } from '../../../../config/helper/utils';

const CardWidgetGastos = (props) => {
	const {
		lancamentos,
		loadingLancamentos,
		title
	} = props;

	const [total, setTotal] = useState(0);
	const [
		totalAnterior,
		setTotalAnterior
	] = useState(0);

	useEffect(() => {
		if (lancamentos !== null) {
			setTotal(
				lancamentos
					.filter(
						(item) =>
							moment(item.dataOperacao).isBetween(
								moment().utc().startOf('month').format(),
								moment().utc().endOf('month').format()
							) && item.tipoLancamento.value === 'gasto'
					)
					.reduce((sum, record) => sum + record.valor, 0)
			);

			setTotalAnterior(
				lancamentos
					.filter(
						(item) =>
							moment(item.dataOperacao).isBetween(
								moment().utc().subtract(1, 'month').startOf('month').format(),
								moment().utc().subtract(1, 'month').endOf('month').format()
							) && item.tipoLancamento.value === 'gasto'
					)
					.reduce((sum, record) => sum + record.valor, 0)
			);
		}
	}, [lancamentos]);

	return (
		!loadingLancamentos && (
			<CardWidget
				title={title}
				color='secondary'
				icon='far fa-credit-card'
				value={total}
				percent={calculaVariacao(total, totalAnterior)}
			/>
		)
	);
};

CardWidgetGastos.propTypes = {
	title: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
	lancamentos: state.arrumaCasaStore.lancamentos,
	loadingLancamentos: state.arrumaCasaStore.loadingLancamentos
});

export default connect(mapStateToProps)(
	CardWidgetGastos
);
