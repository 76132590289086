import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ArrumandoCasaItem from './menuItens/ArrumandoCasaItem';
import FazendoCaixaItem from './menuItens/FazendoCaixaItem';
import InvestidorSucessoItem from './menuItens/InvestidorSucessoItem';
import MestresItem from './menuItens/MestresItem';
import LogoBeganinha from '../../../components/common/SVGIcon';

import { setSideBarToggle } from './action/SideBarAction';

const Sidebar = (props) => {
	const { setSideBarToggle, sideBarToggle } = props;

	const handleClick = (event) => {
		sideBarToggle === 'toggled'
			? setSideBarToggle('')
			: setSideBarToggle('toggled');
	};

	return (
		<nav id='sidebar_id'>
			<ul
				className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${sideBarToggle}`}
				id='accordionSidebar'
			>
				{/* <!-- Sidebar - Brand --> */}
				<Link
					className='sidebar-brand d-flex align-items-center justify-content-center'
					to='/home'
				>
					<LogoBeganinha name='be_branco' classNameCustom='logo-be' />
					{/* <div className='sidebar-brand-text mx-3'>
					</div> */}
				</Link>

				{/* <!-- Divider --> */}
				<hr className='sidebar-divider my-0' />

				{/* <!-- Nav Item - Dashboard --> */}
				<li className='nav-item active'>
					<Link className='nav-link' to='/dashboard' onClick={() => setSideBarToggle('')}>
						<i className='fas fa-fw fa-tachometer-alt'></i>
						<span>Dashboard</span>
					</Link>
				</li>

				{/* <!-- Divider --> */}
				<hr className='sidebar-divider' />
				<ArrumandoCasaItem />

				{/* <!-- Divider --> */}
				<hr className='sidebar-divider' />
				<FazendoCaixaItem />

				{/* <!-- Divider --> */}
				<hr className='sidebar-divider' />
				<InvestidorSucessoItem />

				{/* <!-- Divider --> */}
				<hr className='sidebar-divider' />
				<MestresItem />

				{/* <!-- Divider --> */}
				<hr className='sidebar-divider d-none d-md-block' />

				{/* <!-- Sidebar Toggler (Sidebar) --> */}
				<div className='text-center d-none d-md-inline'>
					<button
						className='rounded-circle border-0'
						id='sidebarToggle'
						onClick={() => handleClick()}
					></button>
				</div>
			</ul>
		</nav>
	);
};

const mapStateToProps = (state) => ({
	sideBarToggle: state.sideBarStore.sideBarToggle,
	usuario: state.authStore.usuario
});

export default connect(mapStateToProps, {
	setSideBarToggle
})(Sidebar);
