import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as Startman } from '../../../assets/img/startman.svg';

const ErroVoltar = ({ history, path}) => {

	return (
		<div className='account-pages mt-5 mb-5'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-lg-5'>
						<div className='card'>
							<div className='card-body p-4'>
								<div className='text-center'>
									<Startman
										id='startman'
										className='logo-startman'
										viewBox='0 0 829 744'
									/>
									<h4 className='text-uppercase text-danger mt-3'>
										Não conseguimos acessar nossos servidores
									</h4>
									<p className='text-muted mt-3'>
										Não conseguimos acessar nossos servidores, vamos voltar para a tela anterior?.
									</p>

									<button
										className='btn btn-info mt-3'
										onClick={() => history.push(path)}
									>
										<i className="far fa-arrow-alt-circle-left"></i> Voltar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ErroVoltar.propTypes = {
	path: PropTypes.any.isRequired
};

export default withRouter(ErroVoltar);
