import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import CardWidget from '../../../components/common/CardWidget';
import { calculaVariacao } from '../../../../config/helper/utils';

const CardWidgetSaldo = (props) => {
	const { title, lancamentos, loadingLancamentos, countup } = props;

	const [total, setTotal] = useState(0);
	const [totalAnterior, setTotalAnterior] = useState(0);

	const calculaSaldoAtual = (lancamentos) => {
		const lancamentosFiltradoMes = lancamentos.filter((item) =>
			moment(item.dataOperacao).isBetween(
				moment().utc().startOf('month').format(),
				moment().utc().endOf('month').format()
			)
		);
		const resulto =
			lancamentosFiltradoMes
				.filter((item) => item.tipoLancamento.value === 'recebimento')
				.reduce((sum, record) => sum + record.valor, 0) -
			lancamentosFiltradoMes
				.filter((item) => item.tipoLancamento.value === 'gasto')
				.reduce((sum, record) => sum + record.valor, 0) -
			lancamentosFiltradoMes
				.filter((item) => item.tipoLancamento.value === 'desejo')
				.reduce((sum, record) => sum + record.valor, 0) -
			lancamentosFiltradoMes
				.filter((item) => item.tipoLancamento.value === 'investimento')
				.reduce((sum, record) => sum + record.valor, 0);
		return resulto;
	};

	const calculaSaldoAnterior = (lancamentos) => {
		const lancamentosFiltradoMes = lancamentos.filter((item) =>
			moment(item.dataOperacao).isBetween(
				moment().utc().subtract(1, 'month').startOf('month').format(),
				moment().utc().subtract(1, 'month').endOf('month').format()
			)
		);
		const resultado =
			lancamentosFiltradoMes
				.filter((item) => item.tipoLancamento.value === 'recebimento')
				.reduce((sum, record) => sum + record.valor, 0) -
			lancamentosFiltradoMes
				.filter((item) => item.tipoLancamento.value === 'gasto')
				.reduce((sum, record) => sum + record.valor, 0) -
			lancamentosFiltradoMes
				.filter((item) => item.tipoLancamento.value === 'desejo')
				.reduce((sum, record) => sum + record.valor, 0) -
			lancamentosFiltradoMes
				.filter((item) => item.tipoLancamento.value === 'investimento')
				.reduce((sum, record) => sum + record.valor, 0);
		return resultado;
	};

	useEffect(() => {
		if (lancamentos !== null) {
			setTotal(calculaSaldoAtual(lancamentos));
			setTotalAnterior(calculaSaldoAnterior(lancamentos));
		}
	}, [lancamentos]);

	return (
		!loadingLancamentos && (
			<CardWidget
				title={title}
				color={total > 0 ? 'primary' : 'secondary'}
				icon='fas fa-wallet'
				value={total}
				percent={calculaVariacao(total, totalAnterior)}
				countup={countup}
			/>
		)
	);
};

CardWidgetSaldo.propTypes = {
	title: PropTypes.string.isRequired,
	countup: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	loadingLancamentos: state.arrumaCasaStore.loadingLancamentos,
	lancamentos: state.arrumaCasaStore.lancamentos
});

export default connect(mapStateToProps)(CardWidgetSaldo);
