import { SEND_EMAIL, ERROR_EMAIL, SET_LOADING, SET_DATA_CONTEXT_ANO } from './types';

import { API } from 'aws-amplify';
const apiName = 'be-send-emails';
const path = '/be-send-emails';

export const sendEmail = (values) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.post(apiName, path, {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				body: values
			})
				.then((res) => {
					dispatch([
						{
							type: SEND_EMAIL,
							payload: values
						}
					]);
					resolve(res);
				})
				.catch((err) => {
					dispatch({
						type: ERROR_EMAIL,
						payload: [err, true]
					});
					reject(err);
				});
		});
};

//Setando o loading para todos os componentes
export const setLoading = (loading) => {
	return {
		type: SET_LOADING,
		payload: loading
	};
};


export const setDataContextAno = (dataContext) => {
	return {
		type: SET_DATA_CONTEXT_ANO,
		payload: dataContext
	};
};