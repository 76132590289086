import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAutorizacaoCei } from '../../../private/dashboard/usuario/action/UsuarioAction';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';
import { formataData } from '../../../../config/helper/utils';

const ValidarConexaoCEI = (props) => {
	const { getAutorizacaoCei, autorizacaoCei } = props;

	useEffect(() => {
		getAutorizacaoCei().then(() => {

		})
		.catch((err) => {
			if (err.code === 'NotAuthorizedException') {
				setErroAutenticacao(err);
			}

		});

		// eslint-disable-next-line
	}, [getAutorizacaoCei]);

	return (
		<>
			{autorizacaoCei && autorizacaoCei !== null ? (
				// <Row>
				// 	<div className='col-12 text-right'>
						<span className='small'>
							{`A última importação de dados do CEI aconteceu em `}
							<strong>{`${formataData(
								autorizacaoCei.dataOperacao
							)}`}</strong>
						</span>
				// 	</div>
				// </Row>
			) : (
				// <Row>
				// 	<div className='col-12 text-right'>
						<Link
							to='/dashboard/usuario/conecte-me'
							className='btn btn-primary'
						>
							Conecte-me ao CEI
						</Link>
				// 	{/* </div>
				// </Row> */}
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	autorizacaoCei: state.usuarioStore.autorizacaoCei
});

export default connect(mapStateToProps, { getAutorizacaoCei })(
	ValidarConexaoCEI
);