import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { setDataContextAno } from './action/CommonAction';

const HeaderBotoesAnos = (props) => {
	const { setDataContextAno, dataContextAno } = props;

	const nextYear = () => {
		let dtContext = Object.assign({}, dataContextAno);
		dtContext = moment(dtContext).add(1, 'year');
		setDataContextAno(dtContext);
	};

	const prevYear = () => {
		let dtContext = Object.assign({}, dataContextAno);
		dtContext = moment(dtContext).subtract(1, 'year');
		setDataContextAno(dtContext);
	};

	return (
		<>
			<div className='col-sm-6 col-md-4'>
				<div className='text-left'>
					<button className='btn btn-outline-secondary' onClick={prevYear}>
						{/* Mês Anterior */}
						{moment(dataContextAno).subtract(1, 'year').year()}
					</button>
				</div>
			</div>
			<div className='col-md-4 d-none d-md-block'>
				<div className='text-center font-weight-bold'>
					{moment(dataContextAno).year()}
				</div>
			</div>
			<div className='col-sm-6 col-md-4'>
				<div className='text-right'>
					<button className='btn btn-outline-primary' onClick={nextYear}>
						{/* Próximo Mês */}
						{moment(dataContextAno).add(1, 'year').year()}
					</button>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	dataContextAno: state.commonStore.dataContextAno
});

export default connect(mapStateToProps, {
	setDataContextAno
})(HeaderBotoesAnos);
