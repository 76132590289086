import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Row from "../../../components/common/Row";
import ModalExplicacaoAnalise from "./ModalExplicacaoAnalise";
import Card from "../../../components/common/Card";
import CardBody from "../../../components/common/CardBody";
import CardHeader from "../../../components/common/CardHeader";
import HeaderBotoesMeses from "../../../components/common/HeaderBotoesMeses";
import HeaderBotoesAnos from "../../../components/common/HeaderBotoesAnos";
import RowAnaliseFinanceira from "./RowAnaliseFinanceira";
import TableTotalAnualizado from "./TableTotalAnualizado";
import RowExplicacaoAnaliseFinanceira from "./RowExplicacaoAnaliseFinanceira";
import RowExplicacaoTotalAnualizado from "./RowExplicacaoTotalAnualizado";
import Erro500 from "../../../components/common/Erro500";
import { carregarUsuario } from "../../../minimal/autenticacao/action/AuthAction";
import { setDataContextLancamentos } from "./action/ArrumandoCasaAction";

const AnaliseFinanceira = (props) => {
  const {
    isErroGetLancamentos,
    carregarUsuario,
    erroAutenticacao,
    erroLancamentos,
    setDataContextLancamentos,
    dataContextLancamentos,
  } = props;

  useEffect(() => {
    // setLoading(true);
    carregarUsuario();
    // eslint-disable-next-line
  }, []);

  if (erroAutenticacao !== null) {
    return <Redirect to="/login" />;
  } else if (isErroGetLancamentos && erroLancamentos !== null) {
    return <Erro500 erro={erroLancamentos} />;
  }

  return (
    <>
      <Card classes="mb-3">
        <CardHeader>
          <RowExplicacaoAnaliseFinanceira />
        </CardHeader>
        <CardBody>
          <Row>
            <HeaderBotoesMeses
              functionName={setDataContextLancamentos}
              dataRedux={dataContextLancamentos}
            />
          </Row>
          <RowAnaliseFinanceira />
        </CardBody>
      </Card>
      <ModalExplicacaoAnalise />
      <Card>
        <CardHeader>
          <RowExplicacaoTotalAnualizado />
        </CardHeader>
        <CardBody>
          <Row>
            <HeaderBotoesAnos />
          </Row>
          <TableTotalAnualizado />
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  isErroGetLancamentos: state.arrumaCasaStore.isErroGetLancamentos,
  erroLancamentos: state.arrumaCasaStore.erroLancamentos,
  dataContextLancamentos: state.arrumaCasaStore.dataContextLancamentos,
  erroAutenticacao: state.authStore.erroAutenticacao,
});
export default connect(mapStateToProps, {
  carregarUsuario,
  setDataContextLancamentos,
})(AnaliseFinanceira);
