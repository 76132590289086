import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Title from '../../../components/common/Title';
import Row from '../../../components/common/Row';
import Card from '../../../components/common/Card';
import CardBody from '../../../components/common/CardBody';
import Erro500 from '../../../components/common/Erro500';
import Spinner from '../../../components/preLoader/Spinner';
import { setLoading } from '../../../components/common/action/CommonAction';
import { carregarUsuario } from '../../../minimal/autenticacao/action/AuthAction';
import {
	getArraySerieMeusObjetivos,
	getArraySerieEvolucaoPatrimonial
} from './action/FazendoCaixaAction';
import GraficoEvolucaoPatrimonial from './GraficoEvolucaoPatrimonial';
import GraficoPizzaPrazoObjetivo from './GraficoPizzaPrazoObjetivo';

const MeusGraficos = (props) => {
	const {
		isErroGetObjetivos,
		loading,
		setLoading,
		erroObjetivos,
		getArraySerieMeusObjetivos,
		getArraySerieEvolucaoPatrimonial,
		erroAutenticacao,
		carregarUsuario
	} = props;

	useEffect(() => {
		setLoading(true);
		Promise.all([
			carregarUsuario(),
			getArraySerieMeusObjetivos(),
			getArraySerieEvolucaoPatrimonial()
		])
			.then(() => {
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});

		// eslint-disable-next-line
	}, []);

	if (erroAutenticacao !== null) {
		return <Redirect to='/login' />;
	} else if (isErroGetObjetivos && erroObjetivos !== null) {
		return <Erro500 erro={erroObjetivos} />;
	}

	if (loading) {
		return <Spinner id='spinner-centro-lg' />;
	}

	return !loading && (
		<>
			<Title
				text='Meus gráficos'
				textTooltip={`Esta funcionalidade te permite fazer diferentes análises do seus objetivos!`}
			/>
			<Row classes='mb-3'>
				<div className='col-12'>
					<Card>
						<CardBody>
							<GraficoPizzaPrazoObjetivo />
						</CardBody>
					</Card>
				</div>
			</Row>
			{/* <Row classes='mb-3'>
					<div className='col-12'>
						<Card>
							<CardBody>
								<GraficoStackedBarLancamentos />
							</CardBody>
						</Card>
					</div>
				</Row> */}
			<Row>
				<div className='col-12'>
					<Card>
						<CardBody>
							<GraficoEvolucaoPatrimonial />
						</CardBody>
					</Card>
				</div>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => ({
	isErroGetObjetivos: state.fazendoCaixaStore.isErroGetObjetivos,
	erroObjetivos: state.fazendoCaixaStore.erroObjetivos,
	loading: state.commonStore.loading,
	erroAutenticacao: state.authStore.erroAutenticacao
});

export default connect(mapStateToProps, {
	setLoading,
	getArraySerieMeusObjetivos,
	getArraySerieEvolucaoPatrimonial,
	carregarUsuario
})(MeusGraficos);
