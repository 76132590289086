import {
	GET_MEUS_OBJETIVOS,
	ERROR_OBJETIVOS,
	ADD_OBJETIVO,
	UPDATE_OBJETIVO,
	HANDLE_OPTION_OBJETIVOS,
	SET_OBJETIVO,
	SET_SHOW_MODAL_OBJETIVOS,
	REMOVE_OBJETIVO,
	GET_MEUS_OBJETIVOS_DETALHES,
	SET_LOADING_OBJETIVOS_DETALHES,
	SET_INDEX_ROW,
	SET_OBJETIVOS_DETALHES_TO_NULL,
	SET_TOTAL_ACUMULADO_OBJETIVOS,
	SET_SHOW_MODAL_ANALISE_OBJETIVO,
	GET_ARRAY_SERIE_MEUS_OBJETIVOS,
	GET_ARRAY_SERIE_EVOLUCAO_PATRIMONIAL,
	CLEAR_STATE_OBJETIVO
} from './types';

import { API } from 'aws-amplify';
import moment from 'moment';

import { calculaPrazo } from '../helper/utils';
const apiName = 'be-meus-objetivos';
const path = '/be-meus-objetivos';

//Action disparada pelo ListaObjetivos para trazer todos os objetivos que há na base.
export const getMeusObjetivos = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/data', {
				response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
			})
				.then((items) => {
					dispatch([
						{
							type: GET_MEUS_OBJETIVOS,
							payload: items.data.Items
						},
						handleOptionsObjetivos(items.data.Items)
					]);
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_OBJETIVOS,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

//Action usada para montar o select box que é usado pelo ModalAddUpdateLancamento
const handleOptionsObjetivos = (objetivos) => {
	const optsObjetivos = [
		{
			label: 'Com qual plano este lançamento está relacionado?',
			value: 0
		}
	];

	// eslint-disable-next-line
	objetivos.map((item) => {
		optsObjetivos.push({
			label: item.nome,
			value: item.sk,
			valorAtual: item.valorAtual
		});
	});

	return {
		type: HANDLE_OPTION_OBJETIVOS,
		payload: optsObjetivos
	};
};

const errorDispatch = (err, erroGet, erroCount, values, showModal = false) => {
	return [
		{
			type: ERROR_OBJETIVOS,
			payload: [err, erroGet, erroCount]
		}
		// setLancamentoCurrent(values, showModal)
	];
};

//Action disparada pelo onSubmit do Formik no ModalAddUpdateObjetivo para incluir ou alterar objetivo.
export const submit = (values) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			if (typeof values.sk === 'undefined') {
				API.post(apiName, path, {
					response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
					body: values
				})
					.then((res) => {
						dispatch([
							{
								type: ADD_OBJETIVO,
								payload: res.data
							}
						]);
						resolve();
					})
					.catch((err) => {
						dispatch(errorDispatch(err, false, true, values, true));
						reject(err);
					});
			} else {
				API.put(apiName, path, {
					response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
					body: values
				})
					.then(() => {
						dispatch([
							{
								type: UPDATE_OBJETIVO,
								payload: values
							}
						]);
						resolve();
					})
					.catch((err) => {
						dispatch(errorDispatch(err, false, true, values, true));
						reject(err);
					});
			}
		});
};

//Action disparada pelo onClick de ícone de remover do ListaObjetvos.
export const remove = (values) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.del(apiName, path, {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				body: values
			})
				.then(() => {
					dispatch([
						{
							type: REMOVE_OBJETIVO,
							payload: values
						}
					]);
					resolve();
				})
				.catch((err) => {
					dispatch(errorDispatch(err, false, true, values));
					reject(err);
				});
		});
};

export const setObjetivoCurrent = (objetivo) => {
	return (dispatch) =>
		dispatch({
			type: SET_OBJETIVO,
			payload: objetivo
		});
};

export const setShowModalObjetivos = (showModal) => {
	return {
		type: SET_SHOW_MODAL_OBJETIVOS,
		payload: showModal
	};
};

export const setShowModalAnaliseObjetivo = (showModal) => {
	return {
		type: SET_SHOW_MODAL_ANALISE_OBJETIVO,
		payload: showModal
	};
};

export const setIndexRow = (index) => {
	return {
		type: SET_INDEX_ROW,
		payload: index
	};
};

// export const setLoadingObjetivos = (value) => {
// 	return {
// 		type: SET_LOADING_OBJETIVOS,
// 		payload: value
// 	};
// };

export const setLoadingObjetivoDetalhes = (value) => {
	return [
		{
			type: SET_LOADING_OBJETIVOS_DETALHES,
			payload: value
		}
	];
};

export const setObjetivoDetalhesToNull = () => {
	return [
		{
			type: SET_OBJETIVOS_DETALHES_TO_NULL
		}
	];
};

export const clearStateObjetivo = () => {
	return {
		type: CLEAR_STATE_OBJETIVO
	};
};

//Action disparada pelo RowCardObjetivos para trazer quais lancamentos o objetivo tem.
export const getMeusObjetivoDetalhes = (skObjetivo) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/details', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					skObjetivo: skObjetivo
				}
			})
				.then((items) => {
					dispatch({
						type: GET_MEUS_OBJETIVOS_DETALHES,
						payload: items.data.Items
					});
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_OBJETIVOS,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

//Action disparada pelo Dashboard.js para alimentar o Widget com o total dos objetivos
export const getTotalObjetivosAcumulado = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/total', {
				response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
			})
				.then((items) => {
					dispatch({
						type: SET_TOTAL_ACUMULADO_OBJETIVOS,
						payload: items.data
					});
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_OBJETIVOS,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};


//Action disparada pelo GraficoPizzaPrazoObjetivo para trazer todos os objetivos que há na base.
export const getArraySerieMeusObjetivos = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/data', {
				response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
			})
				.then((items) => {
					const valores = [];
					valores.push(items.data.Items.filter((item) => calculaPrazo(item.prazo) === 'Curto Prazo').length);
					valores.push(items.data.Items.filter((item) => calculaPrazo(item.prazo) === 'Médio Prazo').length);
					valores.push(items.data.Items.filter((item) => calculaPrazo(item.prazo) === 'Longo Prazo').length);

					dispatch([
						{
							type: GET_ARRAY_SERIE_MEUS_OBJETIVOS,
							payload: [valores, items.data.Items]
						}
					]);
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_OBJETIVOS,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};


//Action disparada pelo GraficoEvolucaoPatrimonial para trazer todos os objetivos que há na base.
export const getArraySerieEvolucaoPatrimonial = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/all-investimentos', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					dataInicio: moment()
					.utc()
					.subtract(11, 'month')
					.startOf('month')
					.format(),
					dataFim: moment().utc().endOf('month').format()
				}
			})
				.then((items) => {
					dispatch([
						{
							type: GET_ARRAY_SERIE_EVOLUCAO_PATRIMONIAL, //TODO
							payload: items.data.Items
						}
					]);
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_OBJETIVOS,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};