import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
// import moment from 'moment';

import { formataMoeda, formataData } from '../../../../config/helper/utils';
import {
	getMinhasAtividadesPeriodo
} from './action/UsuarioAction';
import Spinner from '../../../components/preLoader/Spinner';
import NaoHaAtividades from './NaoHaAtividades';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';
import { setLoading } from '../../../components/common/action/CommonAction';
import Row from '../../../components/common/Row';
import Pagination from '../../../components/common/Pagination';

const ListaAtividades = (props) => {

    const {
		atividades,
		getMinhasAtividadesPeriodo,
		dataContextAtividades,
		setLoading,
		loading,
		setErroAutenticacao
	} = props;

    const [column, setColumn] = useState();
	const [order, setOrder] = useState(false); //Crescente

	useEffect(() => {
		setLoading(true);

		getMinhasAtividadesPeriodo(dataContextAtividades).then(() => {
			setLoading(false);
			setCurrentPage(1); //Voltando para a página 1 após trocar o mês
		}).catch((err) => {
			if (err.code === 'NotAuthorizedException') {
				setErroAutenticacao(err);
			}
			setLoading(false);		
		});

	}, [dataContextAtividades]);

	// =============== bloco necessário para a paginação funcionar =======
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(5);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentArrayPaginated =
	atividades &&
	atividades.slice(indexOfFirstItem, indexOfLastItem);
	// =============== fim do bloco necessário para a paginação funcionar =======

    const renderHeader = () => (
		<tr>
			<th>Descrição</th>
			<th
				onClick={() => {
					setColumn('dataOperacao');
					setOrder(!order);
				}}
			>
				Data
				<span className='ml-2'>
					{order && column === 'dataOperacao' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
			<th>Ações</th>
		</tr>
	);

	const renderRows = (order, column = 'dataOperacao') => {
		return atividades
			.sort((a, b) => {
				if (order) {
					return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
				} else {
					return b[column] < a[column] ? -1 : b[column] > a[column] ? 1 : 0;
				}
			})
			.map((atividade, index) => (
				<tr key={index}>
					<td>{atividade.descricao}</td>
					<td>{formataData(atividade.dataOperacao)}</td>
					<td><i className="fas fa-search-plus"></i></td>
				</tr>
			));
	};    

    if (loading) {
		return <Spinner id='spinner-centro-sm' />;
	}

    return atividades && atividades.length > 0 ? (
		<>
			<Row classes='mt-sm-4 mt-2'>
				<div className='table-responsive fixed-first-row pb-2'>
					{/* <SimpleBar style={{ height: '100%' }}> */}
						<table className='table table-centered mb-0'>
							<thead className='thead-light'>{renderHeader()}</thead>
							<tbody>{renderRows(order, column)}</tbody>
						</table>
					{/* </SimpleBar> */}
				</div>
				<div className='col-12 text-center'>
					{currentArrayPaginated && currentArrayPaginated.length > 0 && (
						<Pagination
							activePage={currentPage}
							itemsPerPage={itemsPerPage}
							totalItems={atividades ? atividades.length : 0}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
						/>
					)}
				</div>
			</Row>
		</>
	) : (
		<NaoHaAtividades height='60vh' />
	);
};

const mapStateToProps = (state) => ({
	atividades: state.usuarioStore.atividades,
	erroCountAtividades: state.usuarioStore.erroCountAtividades,
	isErroGetAtividades: state.usuarioStore.isErroGetAtividades,
	dataContextAtividades: state.usuarioStore.dataContextAtividades,
	loading: state.commonStore.loading,
});

export default connect(mapStateToProps, {
	getMinhasAtividadesPeriodo,
	setLoading,
	setErroAutenticacao
})( ListaAtividades)
