import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import GraficoMinhaCarteira from './investidorSucesso/minhaCarteira/GraficoMinhaCarteira';
import GraficoRecebimentosGastos from './arrumandoCasa/GraficoRecebimentosGastos';
import GraficoEvolucaoPatrimonial from './fazendoCaixa/GraficoEvolucaoPatrimonial';
import MeusUltimosLancamentosTimeline from './arrumandoCasa/MeusUltimosLancamentosTimeline';
import CardWidgetRecebimentos from './arrumandoCasa/CardWidgetRecebimentos';
import CardWidgetGastos from './arrumandoCasa/CardWidgetGastos';
import CardWidgetGastosNaoEssenciais from './arrumandoCasa/CardWidgetGastosNaoEssenciais';
import CardWidgetInvestimentos from './arrumandoCasa/CardWidgetInvestimentos';
import CardWidgetSaldo from './arrumandoCasa/CardWidgetSaldo';
import CardWidgetMeusObjetivos from './fazendoCaixa/CardWidgetMeusObjetivos';
import Spinner from '../../components/preLoader/Spinner';
import { getMes } from '../../../config/helper/utils';
import Row from '../../components/common/Row';
import Erro500 from '../../components/common/Erro500';
import {
	getMinhasContasPeriodoDashboard,
	getValoresLancamentosSumarizadosPorPeriodo
} from './arrumandoCasa/action/ArrumandoCasaAction';
import { getTotalObjetivosAcumulado, getArraySerieEvolucaoPatrimonial } from './fazendoCaixa/action/FazendoCaixaAction';
import { setLoading } from '../../components/common/action/CommonAction';
import { carregarUsuario } from '../../minimal/autenticacao/action/AuthAction';


const Dashboard = (props) => {
	const {
		getMinhasContasPeriodoDashboard,
		getTotalObjetivosAcumulado,
		getValoresLancamentosSumarizadosPorPeriodo,
		getArraySerieEvolucaoPatrimonial,
		loading,
		setLoading,
		carregarUsuario,
		erroAutenticacao
	} = props;

	const mes = getMes(moment().month());

	const [reload, setReload] = useState(false);
	// const [loading, setLoading] = useState(false);
	const [erro, setErro] = useState(false);
	const [erroMsg, setErroMsg] = useState(null);

	useEffect(() => {
		setLoading(true);
		let promises = [
			carregarUsuario(),
			getMinhasContasPeriodoDashboard(),
			getTotalObjetivosAcumulado(),
			getArraySerieEvolucaoPatrimonial(),
			getValoresLancamentosSumarizadosPorPeriodo()
		];
		Promise.all(promises)
			.then(() => {
				// console.log("carregaUsuario - dashboard")
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setErro(true);
				setErroMsg(err);
				// console.log(err)
			});

		// eslint-disable-next-line
	}, [reload]);

	if (loading) {
		return <Spinner id='spinner-centro-lg' />;
	}

	if (erro && erroMsg !== null) {
		if (erroAutenticacao !== null) {
			return <Redirect to='/login' />;
		} else {
			return <Erro500 erro={erroMsg} />;
		}
	}

	//TODO: colocar um IF para checar se deu erro em alguma action
	return (
		<>
			<div className='container-fluid'>
				{/* <!-- Page Heading --> */}
				<div className='d-sm-flex align-items-center justify-content-between mb-4'>
					<h1 className='h3 mb-0 text-gray-800'>Visão geral</h1>
					<button
						onClick={() => setReload(!reload)}
						className='d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm'
					>
						<i className='fas fa-sync-alt text-white-50'></i>
					</button>
				</div>
				{/* <!-- Content Row --> */}
				<Row classes='tour-passo-consolido'>
					<div className='col-lg-4 col-md-6 mb-4'>
						<CardWidgetRecebimentos title={`Recebimentos de ${mes}`} />
					</div>
					<div className='col-lg-4 col-md-6 mb-4'>
						<CardWidgetGastos title={`Gastos essenciais de ${mes}`} />
					</div>
					<div className='col-lg-4 col-md-6 mb-4'>
						<CardWidgetGastosNaoEssenciais
							title={`Gastos não essenciais de ${mes}`}
						/>
					</div>
					<div className='col-lg-4 col-md-6 mb-4'>
						<CardWidgetInvestimentos title={`Investimentos de ${mes}`} />
					</div>
					{/* <i class="fas fa-wallet"></i> */}
					<div className='col-lg-4 col-md-6 mb-4'>
						<CardWidgetSaldo title={`Saldo de ${mes}`} />
					</div>
					<div className='col-lg-4 col-md-6 mb-4'>
						<CardWidgetMeusObjetivos title={`Meus Objetivos`} countup={true} />
					</div>
				</Row>
				<Row>
					<div className='col-xl-8 col-lg-7'>
						<GraficoRecebimentosGastos height={300} />
					</div>
					<div className='col-xl-4 col-lg-5'>
						<MeusUltimosLancamentosTimeline mes={mes} />
					</div>
				</Row>
				{process.env.NODE_ENV === 'development' && (
					<Row>
						<div className='col-xl-8 col-lg-7'>
							<GraficoEvolucaoPatrimonial />
						</div>
						<div className='col-xl-4 col-lg-5'>
							<GraficoMinhaCarteira />
						</div>
					</Row>
				)}
				{process.env.NODE_ENV !== 'development' && (
					<Row>
					<div className='col-12'>
						<GraficoEvolucaoPatrimonial />
					</div>
				</Row>
			)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	dataIncioGraficoRecGastos: state.arrumaCasaStore.dataIncioGraficoRecGastos,
	dataFimGraficoRecGastos: state.arrumaCasaStore.dataFimGraficoRecGastos,
	loading: state.commonStore.loading,
	erroAutenticacao: state.authStore.erroAutenticacao
});

export default connect(mapStateToProps, {
	getMinhasContasPeriodoDashboard,
	getTotalObjetivosAcumulado,
	getArraySerieEvolucaoPatrimonial,
	getValoresLancamentosSumarizadosPorPeriodo,
	setLoading,
	carregarUsuario
})(Dashboard);
