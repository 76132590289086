import {
	CARREGA_USUARIO,
	CARREGA_USUARIO_FALHA,
	LOGOUT_SUCESSO,
	LOGOUT_FALHA,
	SET_GUIDED_TOUR_SUCESSO,
	SET_GUIDED_TOUR_FALHA,
	LOGIN_SUCESSO,
	LOGIN_FALHA,
	REENVIAR_CODIGO_SUCESSO,
	REENVIAR_CODIGO_FALHA,
	ESQUECI_SENHA_SUCESSO,
	ESQUECI_SENHA_FALHA,
	CONFIRMA_CODIGO_SUCESSO,
	CONFIRMA_CODIGO_FALHA,
	CRIA_CONTA_SUCESSO,
	CRIA_CONTA_FALHA,
	SET_ERRO_AUTENTICACAO,
	UPDATE_USER_ATTRIBUTE,
	ERROR_USER_UPDATE
} from './types';

const INITIAL_STATE = {
	usuario: null,
	erroAutenticacao: null,
	'custom:isMostrarGuidedTour': '',
	erroUpdateUser: null,
	erroCountUsuario: 0
};

export const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CARREGA_USUARIO:
		case CRIA_CONTA_SUCESSO:
		case LOGIN_SUCESSO: {
			return {
				...state,
				usuario: action.payload,
				'custom:isMostrarGuidedTour': '0',
				erroAutenticacao: null
			};
		}
		case LOGOUT_FALHA:
		case LOGIN_FALHA:
		case CARREGA_USUARIO_FALHA:
		case CONFIRMA_CODIGO_FALHA:
		case SET_GUIDED_TOUR_FALHA:
		case REENVIAR_CODIGO_FALHA: 
		case CRIA_CONTA_FALHA: 
		case ESQUECI_SENHA_FALHA: {
			return {
				...state,
				erroAutenticacao: action.payload,
				usuario: null,
				'custom:isMostrarGuidedTour': ''
			};
		}
		case LOGOUT_SUCESSO: {
			return {
				...state,
				usuario: null,
				erroAutenticacao: null,
			};
		}
		case REENVIAR_CODIGO_SUCESSO:
		case CONFIRMA_CODIGO_SUCESSO:
		case ESQUECI_SENHA_SUCESSO: {
			return {
				...state
			};
		}
		case SET_GUIDED_TOUR_SUCESSO: {
			return {
				...state, 
				'custom:isMostrarGuidedTour': '0'
			}
		}
		case SET_ERRO_AUTENTICACAO: {
			return {
				...state,
				erroAutenticacao: action.payload
			}
		}
		case UPDATE_USER_ATTRIBUTE: {
			return {
				...state
			}; 
		}
		case ERROR_USER_UPDATE: {
			const hasCount = action.payload[2] && ++state.erroCountUsuario;
			return {
				...state,
				erroUpdateUser: action.payload[0],
				erroCountUsuario: hasCount
			}; 			
		}
		default: {
			return state;
		}
	}
};
