import {
	GET_MINHAS_CONTAS_PERIODO,
	SET_DATA_CONTEXT_LANCAMENTO,
	SET_SHOW_MODAL_LANCAMENTO,
	SET_LOADING_LANCAMENTO,
	SET_LOADING_LANCAMENTO_ANUALIZADO,
	SET_LANCAMENTO,
	ADD_LANCAMENTO,
	UPDATE_LANCAMENTO,
	ERROR_LANCAMENTO,
	REMOVE_LANCAMENTO,
	GET_ARRAY_VALORES_TOTAIS,
	SET_ARRAY_VALORES_MESES,
	GET_MINHAS_CONTAS_PERIODO_CALENDARIO,
	GET_MINHAS_CONTAS_PERIODO_ANUALIZADO,
	GET_SERIE_ARRAY_VALORES_TOTAIS_LANCAMENTOS,
	GET_SERIE_ARRAY_TREEMAP_LANCAMENTOS,
	SET_DATA_CONTEXT_LANCAMENTO_TREEMAP,
	SET_LOADING_LANCAMENTO_TREEMAP,
	CLEAR_STATE_LANCAMENTO
} from './types';
import moment from 'moment';

const INITIAL_STATE = {
	lancamentos: null,
	lancamentosCalendario: null,
	lancamento: null,
	lancamentosTotalAnualizado: null,
	loadingLancamentos: true,
	loadingLancamentosAnualizado: true,
	dataContextLancamentos: moment().utc(),
	erroLancamentos: null,
	erroCountLancamentos: 0,
	isErroGetLancamentos: false,
	arrayValores: null,
	showModalLancamentos: false,
	arrayValoresMesesRecebimentos: null,
	arrayValoresMesesGastos: null,
	arrayValoresMesesDesejos: null,
	arrayValoresMesesInvestimentos: null,
	arraySerieGraficoStackedBar: null,
	categoriesGraficoStackedBar: null,
	lancamentosTreemap: null,
	loadingLancamentosTreemap: true,
	dataContextLancamentosTreemap: moment().utc(),
};
//'2019-10-31T03:00:00.000Z'

export const arrumaCasaReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_LANCAMENTO: {
			return {
				...state,
				lancamentos: [action.payload, ...state.lancamentos],
				lancamento: null,
				loadingLancamentos: false, 
				erroLancamentos: null,
				erroCountLancamentos: 0
			};
		}
		case UPDATE_LANCAMENTO: {
			return {
				...state,
				lancamentos: state.lancamentos.map((lancamento) =>
					lancamento.sk === action.payload.sk ? action.payload : lancamento
				),
				lancamento: null,
				loadingLancamentos: false,
				erroLancamentos: null,
				erroCountLancamentos: 0
			};
		}
		case REMOVE_LANCAMENTO: {
			return {
				...state,
				lancamentos: state.lancamentos.filter(
					(lancamento) => lancamento.sk !== action.payload
				),
				lancamento: null,
				loadingLancamentos: false,
				erroLancamentos: null,
				erroCountLancamentos: 0
			};
		}
		case GET_MINHAS_CONTAS_PERIODO: {
			return {
				...state,
				lancamentos: action.payload,
				erroLancamentos: null,
				erroCountLancamentos: 0,
			};
		}
		case GET_MINHAS_CONTAS_PERIODO_CALENDARIO: {
			return {
				...state,
				lancamentosCalendario: action.payload,
				loadingLancamentos: false,
				erroLancamentos: null,
				erroCountLancamentos: 0,
			};
		}
		case GET_MINHAS_CONTAS_PERIODO_ANUALIZADO: {
			return {
				...state,
				lancamentosTotalAnualizado: action.payload,
				erroLancamentos: null,
				erroCountLancamentos: 0,
			};
		}
		case GET_SERIE_ARRAY_TREEMAP_LANCAMENTOS: {
			return {
				...state,
				lancamentosTreemap: action.payload,
				erroLancamentos: null,
				erroCountLancamentos: 0,
			};
		}
		case GET_ARRAY_VALORES_TOTAIS: {
			return {
				...state,
				arrayValores: action.payload,
				loadingLancamentos: false,
				erroLancamentos: null,
				erroCountLancamentos: 0
			};
		}
		case ERROR_LANCAMENTO: {
			const hasCount = action.payload[2] && ++state.erroCountLancamentos
			return {
				...state,
				erroLancamentos: action.payload[0],
				isErroGetLancamentos: action.payload[1],
				erroCountLancamentos: hasCount,
				loadingLancamentos: false,
				loadingLancamentosAnualizado: false
			};
		}
		case SET_DATA_CONTEXT_LANCAMENTO: {
			return {
				...state,
				dataContextLancamentos: action.payload,
				loadingLancamentos: false,
				erroLancamentos: null,
				erroCountLancamentos: 0
			};
		}
		case SET_DATA_CONTEXT_LANCAMENTO_TREEMAP: {
			return {
				...state,
				dataContextLancamentosTreemap: action.payload,
				erroLancamentos: null,
				erroCountLancamentos: 0
			};
		}
		case SET_SHOW_MODAL_LANCAMENTO: {
			return {
				...state,
				showModalLancamentos: action.payload,
				loadingLancamentos: false
			};
		}
		case SET_LOADING_LANCAMENTO: {
			return {
				...state,
				loadingLancamentos: action.payload,
			};
		}
		case SET_LOADING_LANCAMENTO_ANUALIZADO: {
			return {
				...state,
				loadingLancamentosAnualizado: action.payload,
			};
		}
		case SET_LOADING_LANCAMENTO_TREEMAP: {
			return {
				...state,
				loadingLancamentosTreemap: action.payload,
			};
		}
		case CLEAR_STATE_LANCAMENTO: {
			return INITIAL_STATE;
		}
		case SET_LANCAMENTO: {
			return {
				...state,
				lancamento: action.payload,
				loadingLancamentos: false,
				erroLancamentos: null
			};
		}
		case SET_ARRAY_VALORES_MESES: {
			return {
				...state,
				arrayValoresMesesRecebimentos: action.payload[0],
				arrayValoresMesesGastos: action.payload[1],
				arrayValoresMesesDesejos: action.payload[2],
				arrayValoresMesesInvestimentos: action.payload[3],
				loadingLancamentos: false,
				erroLancamentos: null
			};
		}
		case GET_SERIE_ARRAY_VALORES_TOTAIS_LANCAMENTOS: {
			return {
				...state,
				categoriesGraficoStackedBar: action.payload[0],
				arraySerieGraficoStackedBar: action.payload[1],
				loadingLancamentos: false,
				erroLancamentos: null
			}
		}
		default:
			return state;
	}
};

