import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

import { setWidth, formataMoeda } from '../../../../../config/helper/utils';

const GraficoMinhaCarteira = (props) => {
	const { sideBarToggle } = props;

	const [data] = useState({
		series: [44, 55, 67, 83],
		options: {
			chart: {
				height: 350,
				type: 'radialBar'
			},
			theme: {
				mode: 'light',
				palette: 'palette4',
				monochrome: {
					enabled: false,
					color: '#255aee',
					shadeTo: 'light',
					shadeIntensity: 0.65
				}
			},
			plotOptions: {
				radialBar: {
					dataLabels: {
						name: {
							fontSize: '22px'
						},
						value: {
							fontSize: '16px'
						},
						total: {
							show: true,
							label: 'Total',
							formatter: function (w) {
								let valor = w.globals.seriesTotals.reduce((a, b) => {
									return a + b;
								}, 0);
								return formataMoeda(valor);
							}
						}
					}
				}
			},
			labels: ['Ações', 'FIIs', 'Caixa', 'Fundo de investimentos']
		}
	});

	return (
		<div className='card mb-4 tour-passo-grafico-carteira'>
			<div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
				<h6 className='m-0 font-weight-bold text-primary'>Minha Carteira</h6>
				<div className='dropdown no-arrow'>
					<a
						className='dropdown-toggle'
						href='grafico'
						role='button'
						id='dropdownMenuLink'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'
					>
						<i className='fas fa-ellipsis-v fa-sm fa-fw text-gray-400'></i>
					</a>
					<div
						className='dropdown-menu dropdown-menu-right shadow animated--fade-in'
						aria-labelledby='dropdownMenuLink'
					>
						<div className='dropdown-header'>Ações: </div>
						<a className='dropdown-item' href='detalhes'>
							Detalhes
						</a>
					</div>
				</div>
			</div>
			{/* <!-- Card Body --> */}
			<div className='card-body'>
				<div id='minha-carteira-chart' className='apex-charts'>
					<ReactApexChart
						options={data.options}
						series={data.series}
						type='radialBar'
						height={350}
						width={setWidth(sideBarToggle)}
					/>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	sideBarToggle: state.sideBarStore.sideBarToggle,
	usuario: state.authStore.usuario
});

export default connect(mapStateToProps)(GraficoMinhaCarteira);
