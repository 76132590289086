import React from 'react';
import PropTypes from 'prop-types';

const CardHeader = (props) => {
	const { children, classes } = props;

	return <div className={`card-header py-3 ${classes}`}>{children}</div>;
};

CardHeader.propTypes = {
	classes: PropTypes.string
};

export default CardHeader;
