import React from 'react';

import Title from '../../../components/common/Title';
import { callAlert } from '../../../../config/helper/utils';
const MeusProventos = (props) => {
	return (
		<>
			<Title text='Meus Proventos' />
			{callAlert(props)}
		</>
	);
};

export default MeusProventos;
