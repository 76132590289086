import { SET_TOAST_ERRO, SET_TOAST_SUCESSO } from './types';

const INITIAL_STATE = {
    erro: false
};

export const toastReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_TOAST_SUCESSO: {
            return {
                ...state,erro: false
            }
        }
        case SET_TOAST_ERRO: {
            return {
                ...state,erro: true
            }
        }
        default:
            return state;
    }
}