import React from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { formataData, formataMoeda } from '../../../../config/helper/utils';
import moment from 'moment';

import Spinner from '../../../components/preLoader/Spinner';
import If from '../../../components/common/If';
import Card from '../../../components/common/Card';
import CardHeader from '../../../components/common/CardHeader';
import CardBody from '../../../components/common/CardBody';

const MeusUltimosLancamentosTimeline = (props) => {
	const {
		loadingLancamentos,
		lancamentos
	} = props;

	// useEffect(() => {
	// 	const dataIncio = moment().utc().subtract(1, 'month').format();
	// 	const dataFim = moment().utc().format();

	// 	getMinhasContasPeriodoTimeline(dataIncio, dataFim);
	// 	// eslint-disable-next-line

	// 	// eslint-disable-next-line
	// }, []);

	if (loadingLancamentos) {
		return <Spinner id='spinner-centro-sm' />;
	}

	const renderRows = (arrayItems) => {
		return arrayItems
			.sort((a, b) => {
				return b.dataOperacao < a.dataOperacao
					? -1
					: b.dataOperacao > a.dataOperacao
					? 1
					: 0;
			})
			.map((lancamento, index) => (
				<div className='timeline-item' key={index}>
					<If test={lancamento.tipoLancamento.value === 'recebimento'}>
						<i className='fas fa-dollar-sign bg-primary-lighten text-primary timeline-icon'></i>
					</If>
					<If test={lancamento.tipoLancamento.value === 'gasto'}>
						<i className='far fa-credit-card bg-info-lighten text-info timeline-icon'></i>
					</If>
					<If test={lancamento.tipoLancamento.value === 'desejo'}>
						<i className='fas fa-glass-cheers bg-info-lighten text-info timeline-icon'></i>
					</If>
					<If test={lancamento.tipoLancamento.value === 'investimento'}>
						<i className='fas fa-hand-holding-usd bg-primary-lighten text-primary timeline-icon'></i>
					</If>
					<div className='timeline-item-info'>
						<p
							className={`${
								lancamento.tipoLancamento.value === 'gasto' ||
								lancamento.tipoLancamento.value === 'desejo'
									? 'text-info'
									: 'text-primary'
							} font-weight-bold mb-1 d-block`}
						>
							<If test={lancamento.tipoLancamento.value === 'recebimento'}>
								Recebimento
							</If>
							<If test={lancamento.tipoLancamento.value === 'gasto'}>
								Gasto Essencial
							</If>
							<If test={lancamento.tipoLancamento.value === 'desejo'}>
								Gasto não Essencial
							</If>
							<If test={lancamento.tipoLancamento.value === 'investimento'}>
								Investimento
							</If>
						</p>
						<span className='text-gray-700'>
							<span className='text-uppercase font-weight-bold'>
								{lancamento.nome}
							</span>{' '}
							no valor de{' '}
							<span className='font-weight-bold'>
								{formataMoeda(lancamento.valor)}
							</span>
						</span>
						<p className='mb-0 pb-2'>
							<small className='text-muted'>
								{formataData(lancamento.dataOperacao)}
							</small>
						</p>
					</div>
				</div>
			));
	};

	return (
		!loadingLancamentos && (
			<Card classes='mb-4 tour-passo-atividades-recentes'>
				<CardHeader classes='d-flex flex-row align-items-center justify-content-between'>
					<h6 className='m-0 font-weight-bold text-primary'>
						Minha timeline financeira (30 dias)
					</h6>
					{/* <div className='dropdown no-arrow'>
					<a
						className='dropdown-toggle'
						href='grafico'
						role='button'
						id='dropdownMenuLink'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'
					>
						<i className='fas fa-ellipsis-v fa-sm fa-fw text-gray-400'></i>
					</a>
					<div
						className='dropdown-menu dropdown-menu-right shadow animated--fade-in'
						aria-labelledby='dropdownMenuLink'
					>
						<div className='dropdown-header'>Ações: </div>
						<a className='dropdown-item' href='detalhes'>
							Detalhes
						</a>
					</div>
				</div> */}
				</CardHeader>
				{/* <!-- Card Body --> */}
				<CardBody>
					<SimpleBar style={{ height: 424 }}>
						{!loadingLancamentos &&
						lancamentos &&
						lancamentos.length > 0 ? (
							<div className='timeline-alt pb-0'>{renderRows(lancamentos.filter((item) => 
								moment(item.dataOperacao).isBetween(moment().utc().subtract(1, 'month').format(), moment().utc().format())
							))}</div>
						) : (
							<div className='col-12 text-center align-center'>
								<b>Não há lançamentos na sua timeline</b>
							</div>
						)}
					</SimpleBar>
				</CardBody>
			</Card>
		)
	);
};

const mapStateToProps = (state) => ({
	lancamentos: state.arrumaCasaStore.lancamentos,
	loadingLancamentos: state.arrumaCasaStore.loadingLancamentos
});

export default connect(mapStateToProps)(MeusUltimosLancamentosTimeline);
