import {
	CONNECT_CEI,
    SHOW_MODAL_CONNECT_CEI,
    ERROR_CONECT_CEI,
    ERROR_GET_AUTORIZACAO_CEI,
    GET_AUTORIZACAO_CEI,
    CLEAR_STATE_USUARIO,
    ERROR_ATIVIDADES,
    GET_MINHAS_ATIVIDADES_PERIODO,
    SET_DATA_CONTEXT_ATIVIDADES
} from './types';
import moment from 'moment';

const INITIAL_STATE = {
	showModalConectemeCei: null,
    erroCountConexao: 0,
    erroConnectmeCei: null,
    conexaoCei: null,
    autorizacaoCei: null,
	isErroGetAutorizacaoCei: false,
    erroAtividades: null,
	erroCountAtividades: 0,
	isErroGetAtividades: false,
    dataContextAtividades: moment().utc(),
    atividades: null
};

export const usuarioReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CONNECT_CEI: {
            return {
                ...state,
                conexaoCei: action.payload
            }
        }
		case ERROR_CONECT_CEI: {
            const hasCount = action.payload[1] && ++state.erroCountConexao
            return {
                ...state,
                erroConnectmeCei: action.payload[0],
                erroCountConexao: hasCount,
                isErroGetAutorizacaoCei: true
            }
        }
        case GET_AUTORIZACAO_CEI: {
            return {
                ...state,
                autorizacaoCei: action.payload
            }
        }
		case ERROR_GET_AUTORIZACAO_CEI: {
            const hasCount = action.payload[1] && ++state.erroCountConexao
            return {
                ...state,
                erroConnectmeCei: action.payload[0],
                isErroGetAutorizacaoCei: true,
                erroCountConexao: hasCount,
                autorizacaoCei: null
            }
        }
		case SHOW_MODAL_CONNECT_CEI: {
            return {
                ...state,
                showModalConectemeCei: action.payload
            }
        }
        case ERROR_ATIVIDADES: {
			const hasCount = action.payload[2] && ++state.erroCountAtividades
			return {
				...state,
				erroAtividades: action.payload[0],
				isErroGetAtividades: action.payload[1],
				erroCountAtividades: hasCount,
                
			};
		}
        case GET_MINHAS_ATIVIDADES_PERIODO: {
			return {
				...state,
				atividades: action.payload,
				erroAtividades: null,
				erroCountAtividades: 0,
			};
		}
        case SET_DATA_CONTEXT_ATIVIDADES: {
			return {
				...state,
				dataContextAtividades: action.payload,
				erroAtividades: null,
				erroCountAtividades: 0
			};
		}
		case CLEAR_STATE_USUARIO: {
            return INITIAL_STATE;
        }
        default:
            return state;
    }
}
