import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import moment from 'moment';

import {
	setObjetivoCurrent,
	submit,
	setShowModalObjetivos
} from './action/FazendoCaixaAction';
import {
	success,
	error,
	MSG_TOAST_ERRO_DE_NOVO
} from '../../../components/toast/action/ToastAction';
import Row from '../../../components/common/Row';
import CustomTextInput from '../../../components/form/CustomTextInput';
import Button from '../../../components/form/Button';
import CustomMaskedTextInput from '../../../components/form/CustomMaskedTextInput';
import CurrencyMaskedTextInput from '../../../components/form/CurrencyMaskedTextInput';
import { calculaPrazo } from './helper/utils';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';

const ModalAddUpdateObjetivo = (props) => {
	const {
		showModalObjetivos,
		objetivo,
		setObjetivoCurrent,
		success,
		error,
		submit,
		erroCountObjetivos,
		setShowModalObjetivos,
		setErroAutenticacao
	} = props;

	const [INITIAL_VALUES] = useState({
		nome: '',
		descricao: '',
		dataVencimento: '',
		valorAtual: 0,
		valorMeta: 0,
		prazo: -1
	});

	const [prazo, setPrazo] = useState(-1);
	const [nomePrazo, setNomePrazo] = useState('');

	const validationSchema = Yup.object({
		nome: Yup.string().required('O nome do plano é obrigatório'),
		valorMeta: Yup.string()
			.test(
				'Valor válido',
				'O valor da meta deve ser maior que ZERO',
				(val) => {
					if (val > 0) {
						return true;
					} else {
						return false;
					}
				}
			)
			.required('O Valor da meta é obrigatório'),
		dataVencimento: Yup.string()
			.required('A data de vencimento é obrigatória')
			.test('data-valida', 'Data de vencimento inválida', (value) => {
				return moment(value, 'DD/MM/YYYY').isValid();
			})
			.test(
				'data-menor',
				'Data de vencimento é menor do que a data de hoje',
				(value) => {
					let data = moment(value, 'DD/MM/YYYY');
					return moment(new Date(), 'DD/MM/YYYY').isBefore(data);
				}
			)
	});

	const onSubmit = (values, { setSubmitting, resetForm }) => {
		submit(values)
			.then(() => {
				cleanUp(resetForm);
				success();
				setSubmitting(false);
			})
			.catch((err) => {
				if (err.code === 'NotAuthorizedException') {
					setErroAutenticacao(err);
				} else {
					if (erroCountObjetivos >= 1) {
						error(MSG_TOAST_ERRO_DE_NOVO);
					} else {
						error();
					}
				}
				setSubmitting(false);
			});
	};

	const cleanUp = (resetForm) => {
		setObjetivoCurrent(null);
		setShowModalObjetivos(false);
		resetForm({});
		setPrazo(-1);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={objetivo ? objetivo : INITIAL_VALUES}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ values, isSubmitting, resetForm }) => (
				<Modal
					open={showModalObjetivos}
					onClose={() => {
						cleanUp(resetForm);
					}}
					center
					classNames={{
						modal: 'modal-width'
					}}
				>
					<Row classes='my-3 modal-header'>
						<div className='col-12 text-center'>
							<h3>{objetivo ? `Alterar` : `Novo`} objetivo</h3>
						</div>
					</Row>
					<Form className='form-be-custom'>
						<Row classes='form-group'>
							<div className='col-sm-6 mb-3 mb-sm-0'>
								<label>Nome do objetivo</label>
								<CustomTextInput
									name='nome'
									type='text'
									placeholder='Ex.: Viver de renda passiva'
									className='custom-form-control-retangular'
								/>
							</div>
							<div className='col-sm-6'>
								<label>Data de vencimento do objetivo</label>
								<CustomMaskedTextInput
									name='dataVencimento'
									type='tel'
									mask={[
										/[0-9]/,
										/\d/,
										'/',
										/\d/,
										/\d/,
										'/',
										/\d/,
										/\d/,
										/\d/,
										/\d/
									]}
									placeholder='DD/MM/AAAA'
									className='custom-form-control-retangular'
									// onBlur={handleBlur}
									onBlur={(e) => {
										if (e.target.value !== '') {
											const dataAtual = moment().utc();
											const dataObjetivo = moment(
												e.target.value,
												'DD-MM-YYYY'
											).utc();
											const diff = dataObjetivo.diff(dataAtual, 'month');
											values.prazo = diff;
											setNomePrazo(calculaPrazo(diff, true));
											setPrazo(values.prazo);
										} else {
											setPrazo(-1);
										}
									}}
								/>
							</div>
						</Row>
						<Row classes='form-group'>
							<div className='col-12'>
								<label>Descrição do objetivo</label>
								<CustomTextInput
									name='descricao'
									type='text'
									placeholder='Ex.: Objetivo de viver de renda passiva a partir dos 50 anos de idade'
									className='custom-form-control-retangular'
									size='100'
								/>
							</div>
						</Row>
						<Row classes='form-group'>
							<div className='col-sm-6 mb-3 mb-sm-0'>
								<label>Valor já acumulado</label>
								<CurrencyMaskedTextInput
									name='valorAtual'
									type='text'
									className='custom-form-control-retangular'
								/>
							</div>
							<div className='col-sm-6 mb-3 mb-sm-0'>
								<label>Valor da meta</label>
								<CurrencyMaskedTextInput
									name='valorMeta'
									type='text'
									className='custom-form-control-retangular'
								/>
							</div>
						</Row>
						<Row>
							<div className='col-12 text-left'>
								{values.prazo && values.prazo !== -1 ? (
									<span
										className={`font-weight-bold`}
									>{`Seu objetivo é de ${calculaPrazo(
										values.prazo,
										true
									)}`}</span>
								) : (
									<span
										className={`font-weight-bold ${
											prazo === -1 && `text-white`
										}`}
									>{`Seu objetivo é de ${nomePrazo}`}</span>
								)}
							</div>
						</Row>
						<Row>
							<div className='col-12 text-right modal-footer'>
								<Button
									classes='btn btn-secondary mr-3'
									texto='Cancelar'
									disabled={false}
									onClick={() => {
										cleanUp(resetForm);
									}}
								/>
								<Button
									classes='btn btn-primary'
									texto='Salvar'
									disabled={isSubmitting}
									id='btn-salvar'
								/>
							</div>
						</Row>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

const mapStateToProps = (state) => ({
	showModalObjetivos: state.fazendoCaixaStore.showModalObjetivos,
	objetivo: state.fazendoCaixaStore.objetivo,
	erroCountObjetivos: state.fazendoCaixaStore.erroCountObjetivos
});

export default connect(mapStateToProps, {
	setObjetivoCurrent,
	submit,
	success,
	error,
	setShowModalObjetivos,
	setErroAutenticacao
})(ModalAddUpdateObjetivo);
