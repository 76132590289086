import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import CardWidget from '../../../components/common/CardWidget';
import { calculaVariacao } from '../../../../config/helper/utils';

const CardWidgetRecebimentos = (props) => {
	const { loadingLancamentos, lancamentos, title } = props;
	const [total, setTotal] = useState(0);
	const [
		totalAnterior,
		setTotalAnterior
	] = useState(0);

	useEffect(() => {
		if (lancamentos !== null) {
			setTotal(
				lancamentos
					.filter(
						(item) =>
							moment(item.dataOperacao).isBetween(
								moment().utc().startOf('month').format(),
								moment().utc().endOf('month').format()
							) && item.tipoLancamento.value === 'recebimento'
					)
					.reduce((sum, record) => sum + record.valor, 0)
			);

			setTotalAnterior(
				lancamentos
					.filter(
						(item) =>
							moment(item.dataOperacao).isBetween(
								moment().utc().subtract(1, 'month').startOf('month').format(),
								moment().utc().subtract(1, 'month').endOf('month').format()
							) && item.tipoLancamento.value === 'recebimento'
					)
					.reduce((sum, record) => sum + record.valor, 0)
			);
		}
	}, [lancamentos]);

	return (
		!loadingLancamentos && (
			<CardWidget
				title={title}
				color='primary'
				icon='fas fa-dollar-sign'
				value={total}
				percent={calculaVariacao(
					total,
					totalAnterior
				)}
			/>
		)
	);
};

CardWidgetRecebimentos.propTypes = {
	title: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
	loadingLancamentos: state.arrumaCasaStore.loadingLancamentos,
	lancamentos: state.arrumaCasaStore.lancamentos
});

export default connect(mapStateToProps)(CardWidgetRecebimentos);
