import React from 'react';

import ScrollTo from '../../components/common/ScrollTo';

import NavBar from './NavBar';
import Header from './Header';
import VamosComecar from './VamosComecar';
import Servicos from './Servicos';
import ComoFunciona from './ComoFunciona';
import Footer from './Footer';          

const LandingPage = (props) => {
	return (
		<>
			<div id='home'>
				<NavBar />
				<Header />
			</div>
			<div id='vamos-comecar' className='offset'>
				<VamosComecar />
			</div>
			<div id='servicos' className='offset'>
				<Servicos />
			</div>
			<div id='como-funciona' className='offset'>
				<ComoFunciona />
			</div>
			{/* Será usado qdo o Dashboard estiver pronto e aí colocaremos imagens de lançamentos de despesas, gráficos e etc 
			<div id="images" className="offset">
				<Images />
			</div> */}
			<div id='fale-conosco' className='offset'>
				<Footer />
			</div>
			<ScrollTo onde="#home" />
			{/* end Top Scroll */}
		</>
	);
};

export default LandingPage;
