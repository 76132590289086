export const GET_MINHA_CARTEIRA = 'GET_MINHA_CARTEIRA';
export const GET_DETALHE_CARTEIRA = 'GET_DETALHE_CARTEIRA';
export const SET_SHOW_MODAL_CARTEIRA = 'SET_SHOW_MODAL_CARTEIRA';
export const SET_ATIVO = 'SET_ATIVO';
export const ERROR_CARTEIRA = 'ERROR_CARTEIRA';
export const CLEAR_STATE_CARTEIRA = 'CLEAR_STATE_CARTEIRA';
export const SET_LOADING_CARTEIRA = 'SET_LOADING_CARTEIRA';
export const GET_MINHAS_INSTITUICOES_CART = 'GET_MINHAS_INSTITUICOES_CART';
export const SET_ID_CARTEIRA_CURRENT = 'SET_ID_CARTEIRA_CURRENT';
export const GET_COTACAO_ATIVO = 'GET_COTACAO_ATIVO';
export const SET_CLEAR_ERRO_SEM_ATIVOS = 'SET_CLEAR_ERRO_SEM_ATIVOS';
