import React from 'react';
import { connect } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

import { setWidth, formataMoeda } from '../../../../config/helper/utils';
import Spinner from '../../../components/preLoader/Spinner';
import Card from '../../../components/common/Card';
import CardHeader from '../../../components/common/CardHeader';
import CardBody from '../../../components/common/CardBody';
import Tooltip from '../../../components/common/Tooltip';

const GraficoEvolucaoPatrimonial = (props) => {
	const {
		sideBarToggle,
		totalEvolucaoPatrimonial,
		mesesEvolucaoPatrimonial,
		valoresEvolucaoPatrimonial,
		aportesEvolucaoPatrimonial
	} = props;

	const data = {
		series: [
			{
				name: 'Patrimônio',
				type: 'column',
				data:
					valoresEvolucaoPatrimonial !== null ? valoresEvolucaoPatrimonial : []
			},
			{
				name: 'Aporte no mês',
				type: 'line',
				data:
					aportesEvolucaoPatrimonial !== null ? aportesEvolucaoPatrimonial : []
			}
		],
		options: {
			chart: {
				toolbar: {
					show: false
				},
				type: 'line'
			},
			plotOptions: {
				bar: {
					dataLabels: {
						position: 'top' // top, center, bottom
					}
				}
			},
			dataLabels: {
				enabled: true,
				enabledOnSeries: [1],
				offsetY: -20,
				style: {
					fontSize: '12px',
					colors: ['#304758']
				},
				formatter: function (val) {
					return formataMoeda(val);
				}
			},
			colors: ['#17a2b8', '#ff5a5f', '#17a2b8', '#087e8b', '#dc3545', '#0acf97'],
			xaxis: {
				categories:
					mesesEvolucaoPatrimonial !== null ? mesesEvolucaoPatrimonial : 0,
				position: 'top',
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				},
				crosshairs: {
					fill: {
						type: 'gradient',
						gradient: {
							colorFrom: '#74D7D7',
							colorTo: '#17BEBB',
							stops: [0, 100],
							opacityFrom: 0.4,
							opacityTo: 0.5
						}
					}
				},
				tooltip: {
					enabled: false
				}
			},
			yaxis: {
				axisBorder: {
					show: false
				},
				axisTicks: {
					show: false
				},
				labels: {
					show: false,
					formatter: function (val) {
						return formataMoeda(val);
					}
				}
			}
		}
	};

	return (
		<Card classes='mb-4 tour-passo-grafico-evolucao-patrimonial'>
			<CardHeader classes='d-flex flex-row align-items-center'>
				<h6 className='m-0 font-weight-bold text-primary'>
					Evolução Patrimonial nos últimos 12 meses
				</h6>
				<Tooltip
					text={`O gráfico mostra o valor acumulado, ou seja, os valores que foram lançados como investimento, ao longo dos nos últimos 12 meses.`}
					icon='fas fa-1x fa-info-circle'
					trigger='hover'
				/>
			</CardHeader>
			{/* <!-- Card Body --> */}
			<CardBody>
				{totalEvolucaoPatrimonial === null ? (
					<Spinner id='spinner-centro-sm' />
				) : (
					<>
						<div className='chart-content-bg d-none d-md-block'>
							<div className='row text-center'>
								<div className='col-12'>
									<p className='text-muted mb-0 mt-3'>Total</p>
									<h2 className='font-weight-normal mb-3'>
										<small className='mdi mdi-checkbox-blank-circle text-success align-middle mr-1'></small>
										<span>{formataMoeda(totalEvolucaoPatrimonial)}</span>
									</h2>
								</div>
							</div>
						</div>
						<div id='investimentos-evolucao-chart' className='apex-charts'>
							<ReactApexChart
								options={data.options}
								series={data.series}
								height={350}
								width={setWidth(sideBarToggle)}
							/>
						</div>
					</>
				)}
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state) => ({
	sideBarToggle: state.sideBarStore.sideBarToggle,
	usuario: state.authStore.usuario,
	totalEvolucaoPatrimonial: state.fazendoCaixaStore.totalEvolucaoPatrimonial,
	mesesEvolucaoPatrimonial: state.fazendoCaixaStore.mesesEvolucaoPatrimonial,
	valoresEvolucaoPatrimonial:
		state.fazendoCaixaStore.valoresEvolucaoPatrimonial,
	aportesEvolucaoPatrimonial: state.fazendoCaixaStore.aportesEvolucaoPatrimonial
});

export default connect(mapStateToProps)(GraficoEvolucaoPatrimonial);
