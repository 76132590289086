import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';

import {
	carregarUsuario
} from '../layouts/minimal/autenticacao/action/AuthAction';
import Spinner from '../layouts/components/preLoader/Spinner';
// import Bouncer from '../layouts/components/common/BouncePreLoader';

const PrivateRouteWithLayout = (props) => {
	const {
		layout: Layout,
		component: Component,
		carregarUsuario,
		loading,
		erroAutenticacao,
		usuario,
		...rest
	} = props;
	// const [loading, setLoading] = useState(false);
	// const [erroAutenticacao, setErroAutenticacao] = useState(null);

	useEffect(() => {
		// setLoading(true);
		carregarUsuario().then(() => {
			// console.log("carregaUsuario")
			// setLoading(false);
			
		}).catch((err) => {
			// setLoading(false);
			// setErroAutenticacao(err);
		});
		// eslint-disable-next-line
	}, []);

	if (loading && erroAutenticacao === null) {
		return <Spinner id='spinner-centro-lg' />;
	} else if (!loading && erroAutenticacao !== null) {
		return <Redirect to='/login' />;
	} else {
		return (
			<Route
				{...rest}
				render={(matchProps) =>
					usuario !== null && (
						<Layout>
							<Component {...matchProps} />
						</Layout>
					)
				}
			/>
		);
	}
};

PrivateRouteWithLayout.propTypes = {
	component: PropTypes.any.isRequired,
	layout: PropTypes.any.isRequired,
	path: PropTypes.string
};

const mapStateToProps = (state) => ({
	usuario: state.authStore.usuario,
	erroAutenticacao: state.authStore.erroAutenticacao,
	loading: state.authStore.loading,
});

export default connect(mapStateToProps, {
	carregarUsuario
})(PrivateRouteWithLayout);
