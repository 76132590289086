import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CountUp from 'react-countup';

import { formataMoeda } from '../../../../config/helper/utils';
import Card from '../../../components/common/Card';
import CardBody from '../../../components/common/CardBody';

const CardWidgetMeusObjetivos = (props) => {
	const {
		totalObjetivosAcumulado,
		loadingLancamentos,
		title,
		countup = false
	} = props;

	return (
		!loadingLancamentos && (
			<Card classes='widget-flat' style={{ minHeight: '200px' }}>
				<CardBody>
					<div className='float-right'>
						<i
							className={`fas fa-piggy-bank fa-2x widget-icon bg-primary-lighten text-primary pt-1 pb-2`}
						></i>
					</div>
					<h5 className='text-muted font-weight-normal mt-0' title={title}>
						{title}
					</h5>
					<h3 className='mt-3 mb-3'>
						{countup ? (
							<CountUp
								end={totalObjetivosAcumulado}
								separator='.'
								decimals={2}
								decimal=','
								prefix='R$ '
							/>
						) : (
							formataMoeda(totalObjetivosAcumulado)
						)}
					</h3>
					<p className='mb-0 text-muted'>
						<span>Somatório do valor acumulado até aqui em todos seus objetivos</span>
					</p>
				</CardBody>
			</Card>
		)
	);
};

CardWidgetMeusObjetivos.propTypes = {
	title: PropTypes.string.isRequired,
	countup: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	totalObjetivosAcumulado: state.fazendoCaixaStore.totalObjetivosAcumulado,
	loadingObjetivos: state.fazendoCaixaStore.loadingObjetivos
});

export default connect(mapStateToProps)(CardWidgetMeusObjetivos);
