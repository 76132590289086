import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
	getMeusObjetivos,
	setIndexRow,
	setLoadingObjetivoDetalhes,
	setObjetivoDetalhesToNull,
	getMeusObjetivoDetalhes
} from './action/FazendoCaixaAction';
import { setLoading } from '../../../components/common/action/CommonAction';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';
import Card from '../../../components/common/Card';
import CardBody from '../../../components/common/CardBody';
import CardFooter from '../../../components/common/CardFooter';
import Row from '../../../components/common/Row';
import { formataMoeda } from '../../../../config/helper/utils';
import { calculaPrazo } from './helper/utils';
import Spinner from '../../../components/preLoader/Spinner';
import ProgressBar from '../../../components/common/ProgressBar';
import NaoHaObjetivos from './NaoHaObjetivos';
import RowCardObjetivoDetalhe from './RowCardObjetivoDetalhe';
import Button from '../../../components/form/Button';

const RowCardObjetivos = (props) => {
	const {
		loading,
		objetivos,
		setLoading,
		getMeusObjetivos,
		setIndexRow,
		setLoadingObjetivoDetalhes,
		setObjetivoDetalhesToNull,
		getMeusObjetivoDetalhes,
		idx,
	} = props;

	useEffect(() => {
		setLoading(true);
		setLoadingObjetivoDetalhes(true);
		setObjetivoDetalhesToNull();
		setIndexRow(-99);

		getMeusObjetivos().then(() => {
			setLoading(false);
		}).catch((err) => {
			if (err.code === 'NotAuthorizedException') {
				setErroAutenticacao(err);
			}
			setLoading(false);
		});

		// eslint-disable-next-line
	}, []);

	const handleClickDetalhes = (skObjetivo) => {
		setLoadingObjetivoDetalhes(true);
		setObjetivoDetalhesToNull();
		getMeusObjetivoDetalhes(skObjetivo).then(() => {
			setLoadingObjetivoDetalhes(false);
		}).catch((err) => {
			if (err.code === 'NotAuthorizedException') {
				setErroAutenticacao(err);
			}
			setLoadingObjetivoDetalhes(false);
		});
	};

	if (loading) {
		return <Spinner id='spinner-centro-lg' />;
	}

	return !loading && objetivos && objetivos.length > 0 ? (
		objetivos.map((objetivo, index) => (
			<Card key={index} classes='mb-2'>
				<CardBody>
					<p className='text-center font-weight-bold h5'>{objetivo.nome}</p>
					<Row classes='mx-4 mt-2'>
						<ProgressBar
							done={
								objetivo.percentualAtingido <= 100
									? Math.round(
											(objetivo.percentualAtingido + Number.EPSILON) * 100
									  ) / 100
									: 100
							}
						/>
					</Row>
					<Row classes='mx-4 mt-2'>
						<div className='col-6'>
							Valor acumulado:
							<span className=' ml-1 font-weight-bold'>
								{formataMoeda(objetivo.valorAtual)}
							</span>
						</div>
						<div className='col-6'>
							Meta:
							<span className=' ml-1 font-weight-bold'>
								{formataMoeda(objetivo.valorMeta)}
							</span>
						</div>
					</Row>
					<Row classes='mx-4 mt-2'>
						<div className='col-6'>
							Prazo:
							<span className=' ml-1 font-weight-bold'>
								{calculaPrazo(objetivo.prazo, true)}
							</span>
						</div>
						<div className='col-6'>
							Data do vencimento do objetivo:
							<span className='ml-1 font-weight-bold'>
								{objetivo.dataVencimento}
							</span>
						</div>
					</Row>
				</CardBody>
				<CardFooter classes='text-right mb-4'>
					{idx !== index && (
						<Button
							classes='btn btn-primary rounded-pill my-0'
							icon='fas fa-plus'
							disabled={false}
							onClick={() => {
								setIndexRow(index);
								handleClickDetalhes(objetivo.sk);
							}}
						/>
					)}
					{idx === index && (
						<Button
							classes='btn btn-secondary rounded-pill my-0'
							icon='fas fa-minus'
							disabled={false}
							onClick={() => {
								setIndexRow(-99);
							}}
						/>
					)}

					<RowCardObjetivoDetalhe
						index={index}
					/>
				</CardFooter>
			</Card>
		))
	) : (
		<>
			<NaoHaObjetivos height='60vh'/>
		</>
	);
};

const mapStateToProps = (state) => ({
	objetivos: state.fazendoCaixaStore.objetivos,
	loading: state.commonStore.loading,
});

export default connect(mapStateToProps, {
	setLoading,
	getMeusObjetivos,
	setIndexRow,
	setLoadingObjetivoDetalhes,
	setObjetivoDetalhesToNull,
	getMeusObjetivoDetalhes
})(RowCardObjetivos);
