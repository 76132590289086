import React from 'react';

import Title from '../../../components/common/Title';
import { callAlert } from '../../../../config/helper/utils';
const MeusGraficos = (props) => {
	return (
		<>
			<Title text='Meus Gráficos' textTooltip='TimeLine de investimentos?'/>
			{callAlert(props)}
		</>
	);
};

export default MeusGraficos;
