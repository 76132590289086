import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CardValueWithLeftColor from '../../../components/common/CardValueWithLeftColor';

const CardQuantoRecebi = (props) => {
	const {
		loading,
		lancamentos,
		title = 'Quanto recebi'
	} = props;

	const [totalRecebimentos, setTotalRecebimentos] = useState(0);

	useEffect(() => {
		setTotalRecebimentos(
			lancamentos &&
				lancamentos.length > 0
				? lancamentos
						.filter((item) => item.tipoLancamento.value === 'recebimento')
						.reduce((sum, record) => sum + record.valor, 0)
				: 0
		);


		// eslint-disable-next-line
	}, [lancamentos])


	return (
		!loading && (
			<CardValueWithLeftColor
				title={title}
				leftColor='border-left-primary'
				icon='fas fa-dollar-sign'
				// icon='fas fa-arrow-down'
				value={totalRecebimentos}
				countup={false}
			/>
		)
	);
};

CardQuantoRecebi.propTypes = {
	title: PropTypes.string
};

const mapStateToProps = (state) => ({
	loading: state.commonStore.loading,
	lancamentos: state.arrumaCasaStore.lancamentos,
});

export default connect(mapStateToProps)(
	CardQuantoRecebi
);
