// import { ReactComponent as LogoBeganinha } from '../../../assets/img/logo_beganinha.svg';
import { ReactComponent as LogoBeganinha } from '../../../assets/img/logo_beganinha_v2.svg';

import React from 'react';
import PropTypes from 'prop-types';

const getViewBox = (name) => {
	switch (name) {
		case 'beganinha_verde_claro':
			return '149 406.2 348.7 87.2';
		case 'be_verde_escuro':
			return '570.4 288.1 87.6 75.3';
		case 'beganinha_verde_escuro':
			return '149 284 348.7 87.2';
		case 'be_verde_claro':
			return '570.4 412.5 87.6 75.3';
		case 'beganinha_preto':
			return '149 525.9 348.7 87.2';
		case 'be_preto':
			return '570.4 529 87.6 75.3';
		case 'beganinha_branco':
			return '149 658.2 348.7 87.2';
		case 'be_branco':
			return '570.4 663.5 87.6 75.3';
		default:
			return '0 0 50 50';
	}
};

const SVGIcon = ({ id = '', name = '', classNameCustom = '', ...rest }) => (
	// <LogoBeganinha className={className} viewBox={getViewBox(name)} width={getWidth(sizeLogo)} height={getHeight(sizeLogo)}/>
	<LogoBeganinha
		id={id}
		className={classNameCustom}
		viewBox={getViewBox(name)}
		{...rest}
	/>
);

SVGIcon.propTypes = {
	classNameCustom: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
};
export default SVGIcon;
