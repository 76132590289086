import { SET_TAB } from './types';

const INITIAL_STATE = {
	tabSelected: 0
};

export const tabsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_TAB: {
			return {
				...state,
				tabSelected: action.payload
			};
		}
		default:
			return state;
	}
};
