import React from 'react';
import { connect } from 'react-redux';
import Row from '../../../components/common/Row';
import { formataMoeda } from '../../../../config/helper/utils';
import {
	calculaAporteMensal,
	obtemTaxa,
	obtemTextoTaxaByPrazo,
	calculaPrazo
} from './helper/utils';
import Tooltip from '../../../components/common/Tooltip';

const SimulacaoObjetivo = (props) => {
	const { objetivo } = props;

	return (
		objetivo && (
			<div className='mx-4'>
				<Row classes='mb-3'>
					<div className='col-sm-6 col-lg-3'>Objetivo:</div>
					<div className='col-sm-6 col-lg-9 font-weight-bold'>
						{objetivo.nome}
					</div>
				</Row>
				<Row classes='mb-3'>
					<div className='col-sm-6 col-lg-2'>Data de Vencimento:</div>
					<div className='col-sm-6 col-lg-2 font-weight-bold'>
						{objetivo.dataVencimento}
					</div>
					<div className='col-sm-6 col-lg-2'>Valor da Atual:</div>
					<div className='col-sm-6 col-lg-2 font-weight-bold'>
						{formataMoeda(objetivo.valorAtual)}
					</div>
					<div className='col-sm-6 col-lg-2'>Valor da Meta:</div>
					<div className='col-sm-6 col-lg-2 font-weight-bold'>
						{formataMoeda(objetivo.valorMeta)}
					</div>
				</Row>
				<Row>
					<div className='col-12'>
						<p className='h4 text-center'>Simulação</p>
					</div>
				</Row>
				<Row classes='mb-3'>
					<div className='col-sm-6 col-lg-3'>
						Taxa:
						<Tooltip
							text={obtemTextoTaxaByPrazo(objetivo.prazo)}
							icon='fas fa-1x fa-info-circle'
						/>
					</div>
					<div className='col-sm-6 col-lg-9 font-weight-bold'>
						{obtemTaxa(objetivo.prazo)} %
					</div>
				</Row>
				<Row classes='mb-3'>
					<div className='col-sm-6 col-lg-3'>
						Prazo:
						<Tooltip
							text='Prazo é a diferença, em meses, da data atual até a data de vencimento do objetivo'
							icon='fas fa-1x fa-info-circle'
						/>
					</div>
					<div className='col-sm-6 col-lg-9 font-weight-bold'>
						{objetivo.prazo} meses - {calculaPrazo(objetivo.prazo)}
					</div>
				</Row>
				<Row>
					<div className='col-12'>
						<p>
							Para atingir o valor da meta, você precisa aportar mensalmente
							<span className='font-weight-bold ml-2'>
								{formataMoeda(calculaAporteMensal(objetivo))} / mês
							</span>
							.
						</p>
					</div>
				</Row>
			</div>
		)
	);
};

const mapStateToProps = (state) => ({
	objetivo: state.fazendoCaixaStore.objetivo
});
export default connect(mapStateToProps)(SimulacaoObjetivo);
