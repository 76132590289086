import React, { useState } from 'react';

import Row from './Row';

const Pagination = (props) => {
	const { itemsPerPage, totalItems, activePage, currentPage, setCurrentPage } =
		props;

	const pages = [];
	const [pageNumberLimit] = useState(5);
	const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
	const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

	for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
		pages.push(i);
	}

	const handleClick = (event) => {
		setCurrentPage(Number(event.target.id));
	};

	const handleNextbtn = () => {
		setCurrentPage(currentPage + 1);

		if (currentPage + 1 > maxPageNumberLimit) {
			setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
			setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
		}
	};

	const handlePrevbtn = () => {
		setCurrentPage(currentPage - 1);

		if ((currentPage - 1) % pageNumberLimit === 0) {
			setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
			setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
		}
	};

	let pageIncrementBtn = null;
	if (pages.length > maxPageNumberLimit) {
		pageIncrementBtn = (
			<li className='page-link font-weight-bold' onClick={handleNextbtn}>
								{' '}
				&hellip;{' '}
			</li>
		);
	}

	let pageDecrementBtn = null;
	if (minPageNumberLimit >= 1) {
		pageDecrementBtn = (
			<li className='page-link font-weight-bold' onClick={handlePrevbtn}>
				{' '}
				&hellip;{' '}
				{/* <i className='fas fa-ellipsis-h'></i> */}
			</li>
		);
	}

	const renderPageNumbers = pages.map((number) => {
		if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
			return (
				<li
					key={number}
					id={number}
					onClick={handleClick}
					className={`page-link ${
						activePage === number && `active text-secondary font-weight-bold`
					}`}
				>
					{number}
				</li>
			);
		} else {
			return null;
		}
	});

	return (
		<Row classes='mt-2'>
			<div className='col-12 d-flex justify-content-center'>
				<nav>
					<ul className='pagination'>
						<li className='page-item'>
							<button
								className='page-link'
								onClick={handlePrevbtn}
								disabled={currentPage === pages[0] ? true : false}
							>
								<i className='fas fa-chevron-left'></i>
							</button>
						</li>
						{pageDecrementBtn}
						{renderPageNumbers}
						{pageIncrementBtn}
						<li className='page-item'>
							<button
								className='page-link'
								onClick={handleNextbtn}
								disabled={
									currentPage === pages[pages.length - 1] ? true : false
								}
							>
								<i className='fas fa-chevron-right'></i>
							</button>
						</li>
					</ul>
				</nav>
			</div>
		</Row>
	);
};

export default Pagination;

// { itemsPerPage, totalItems, paginate, activePage } (props)
// const pageNumbers = [];

// for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
// 	pageNumbers.push(i);
// }

// return (
// 	<Row classes='mt-2'>
// 		<div className='col-12 d-flex justify-content-center'>
// 			<nav>
// 				<ul className='pagination'>
// 					{pageNumbers.map((number) => (
// 						<li key={number} className='page-item'>
// 							<button onClick={() => paginate(number)} className={`page-link ${activePage === number && `active`}`}>
// 								{number}
// 							</button>
// 						</li>
// 					))}
// 				</ul>
// 			</nav>
// 		</div>
// 	</Row>
// );
// pages.map((number) => (
// 	<If
// 		test={
// 			number < maxPageNumberLimit + 1 && number > minPageNumberLimit
// 		}
// 	>
// 		<li key={number} className='page-item'>
// 			<button
// 				onClick={handleClick}
// 				className={`page-link ${activePage === number && `active`}`}
// 			>
// 				{number}
// 			</button>
// 		</li>
// 	</If>
// ))
