import React, { useEffect, useState } from "react";
import moment from "moment";

import { getMes } from "../../../config/helper/utils";

const HeaderBotoesMeses = (props) => {
  const { functionName, dataRedux } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(dataRedux);

    // eslint-disable-next-line
  }, [dataRedux]);

  const nextMonth = () => {
    let dtContext = Object.assign({}, data);
    dtContext = moment(dtContext).add(1, "month");
    functionName(dtContext);
  };

  const prevMonth = () => {
    let dtContext = Object.assign({}, data);
    dtContext = moment(dtContext).subtract(1, "month");
    functionName(dtContext);
  };

  return (
    <div className="col-12 d-flex">
      <div className="col-sm-6 col-md-4">
        <div className="text-left">
          <button className="btn btn-outline-secondary" onClick={prevMonth}>
            {/* Mês Anterior */}
            {getMes(moment(data).subtract(1, "month").month())}
          </button>
        </div>
      </div>
      <div className="col-md-4 d-none d-md-block">
        <div className="text-center font-weight-bold">
          {getMes(moment(data).month())} / {moment(data).year()}
        </div>
      </div>
      <div className="col-sm-6 col-md-4">
        <div className="text-right">
          <button className="btn btn-outline-primary" onClick={nextMonth}>
            {/* Próximo Mês */}
            {getMes(moment(data).add(1, "month").month())}
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeaderBotoesMeses;
