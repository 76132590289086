import React from 'react';
import PropTypes from 'prop-types';

const CardBody = (props) => {
	const { children, classes } = props;

	return <div className={`card-body ${classes}`}>{children}</div>;
};

CardBody.propTypes = {
	classes: PropTypes.string
};

export default CardBody;