import { SET_PASSWORD_VISIBILITY, CLEAR_STATE_FORM } from "./types";

const INITIAL_STATE = {
  passwordVisibility: false
  
};

export const formReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PASSWORD_VISIBILITY: {
      return {
        ...state,
        passwordVisibility: action.payload
      };
    }
    case CLEAR_STATE_FORM: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};
